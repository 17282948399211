import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../services/user';
import { Subscription } from 'rxjs';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';



@Component({
  selector: 'app-editar-clases',
  templateUrl: './editar-clases.component.html',
  styleUrls: ['./editar-clases.component.scss']
})
export class EditarClasesComponent implements OnInit, AfterViewInit {
  public isAdmin: boolean;
  //private sub: Subscription;
  public user: User;
  public clases: any[];
  public displayedColumns: Array<string>;
  public dataSource;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {
    //this.isAdmin = environment.admins.indexOf(this.user.email) > -1;
    this.clases = [];
    this.displayedColumns = ['title', 'category', 'seccion', /*'sudorimetro',*/ 'pesas', 'step', 'cuerda', 'thumbnail', 'editar'];
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getClases();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(
      () => {
        this.paginator.pageIndex = 0;
        //this.paginator.pageSize = 50;
        //this.paginator.pageSizeOptions = [10, 50, 100];
        const active = this.sort.active;
        const direction = this.sort.direction;
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator.pageSize = 50;
    this.dataSource.paginator.pageSizeOptions = [10, 50, 100];
    this.dataSource.sort = this.sort;
  }

  private getClases(): void {
    this.db.collection('workouts', ref => ref.orderBy('category')).valueChanges({ idField: 'documentId' }).subscribe(
      clases => {
        this.clases = clases;
        this.dataSource.data = clases;
        //console.log(clases);
      },
      error => {
        console.log(error);
      }
    );
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public editarClase(id) {
    console.log(id);
  }
}
