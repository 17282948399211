import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {
  private headers;
  constructor(
    private http: HttpClient
  ) { }

  public getThumbnail(id:any):Observable<any>{
    this.headers = {
      'Authorization': `Bearer 961ae8137752983dc2c2bc77305be295`,
      'Accept': 'application/vnd.vimeo.*+json;version=3.4'
    };
    return this.http.get(`https://api.vimeo.com/videos/${id}/pictures`,  { headers: this.headers });
  }

  public getAccessToken():Observable<any>{
    this.headers = {
      'Authorization': `basic ` + btoa('c346170629c1d80c614c3131251e7edd25e7a529:5bdL8FWc0J4yS1k+pF2C+9H5Eu2gpwC1tOn7E6njGZfa7VDHsOBFBo0YYUEs2hsb67Ti5gSVxA3j0rfZJLRR/TAHgWQXSSEmebIOnwduFFJUAK1OplfincsdeppreK7j'),
      'Content-Type' : 'application/json',
      'Accept'       : 'application/vnd.vimeo.*+json;version=3.4'
    };

    const params = JSON.stringify({
      "grant_type": "client_credentials",
      "scope": "public"
    })
    return this.http.post(`https://api.vimeo.com/oauth/authorize/client`, params, { headers: this.headers });
  }
}
