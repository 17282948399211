import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resolve } from 'url';
declare let $: any;

@Component({
  selector: 'app-crear-template',
  templateUrl: './crear-template.component.html',
  styleUrls: ['./crear-template.component.scss']
})
export class CrearTemplateComponent implements OnInit, OnDestroy {
  public templates: any[];
  public form: FormGroup;
  private sub: any;
  private sub2: any;
  public categorias: string[];
  public categoriaSeleccionada: string;
  public videos: any[];
  public videoSeleccionado: string;
  public workouts: any[];
  public templateSeleccionado: string;

  constructor(private db: AngularFirestore) {
    this.templates = [];
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      dia: new FormControl('1', [Validators.required]),
      comentarios: new FormControl('', [])
    });
    this.categorias = [];
    this.categoriaSeleccionada = '';
    this.videos = [];
    this.videoSeleccionado = '';
    this.workouts = [];
    this.templateSeleccionado = '';
  }

  ngOnInit() {
    this.getTemplates();
    this.getVideos();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
    if(this.sub2) this.sub2.unsubscribe();
  }

  public getTemplates(): void{
    this.sub = this.db.collection('templates').valueChanges({ idField: 'documentId' }).subscribe(
      templates => {
        this.templates = templates;
      },
      error => {
        console.log(error);
      }
    );
  }

  public modalCrearTemplate(): void{
    this.categoriaSeleccionada = '';
    this.videoSeleccionado = '';
    this.workouts = [];
    this.form.setValue({
      name: '',
      dia: '1',
      comentarios: ''
    });
    this.templateSeleccionado = '';
    $('form').removeClass('was-validated');
    $('#modal-template').modal('show');
  }

  private getVideos(): void{
    this.sub2 = this.db.collection('workouts', ref => ref.orderBy('category').orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      (videos: any) => {
        this.categorias = [];
        videos.forEach(v => {
          if(this.categorias.indexOf(v.category) == -1) this.categorias.push(v.category);
        });
        this.videos = videos;
      },
      error => {
        console.log(error);
      }
    );
  }

  public videosPorCategoria(): any[]{
    return this.categoriaSeleccionada ? this.videos.filter(v => v.category == this.categoriaSeleccionada) : this.videos;
  }

  public agregarWorkout(): void{
    if(!this.videoSeleccionado) return;
    const video = this.videos.find(v => v.documentId == this.videoSeleccionado);
    const index = this.workouts.findIndex(w => w.dia == this.form.value.dia);
    index == -1 ? this.workouts.push({ dia: this.form.value.dia, workouts: [video] }) : this.workouts[index].workouts.push(video);
  }

  public eliminarVideo(dia, videoId): void{
    const indexDia = this.workouts.findIndex(w => w.dia == dia);
    const indexVideo = this.workouts[indexDia].workouts.findIndex(w => w.documentId == videoId);

    this.workouts[indexDia].workouts.splice(indexVideo, 1);
    if(this.workouts[indexDia].workouts.length == 0) this.workouts.splice(indexDia, 1);
  }

  public async submit(): Promise<void>{
    if(this.form.valid && this.workouts.length > 0){
      if(this.templateSeleccionado){
        //Borrar documento 
        await this.db.collection('templates').doc(this.templateSeleccionado).delete();
      } 

      this.db.collection('templates').add({ name: this.form.value.name }).then(ref => {
        let promises = this.workouts.map(w => {
          const workout = {
            dia: w.dia,
            comentarios: w.dia == '1' ? this.form.value.comentarios : '',
            workouts: w.workouts.map(v => v.documentId)
          };
          return ref.collection('rutinas').add(workout);
        });
        Promise.all(promises).then(() => {
          $('#modal-template').modal('hide');
        });
      });
    }
  }

  public async modalEditarTemplate(template): Promise<void>{
    this.categoriaSeleccionada = '';
    this.videoSeleccionado = '';
    this.workouts = await this.db.collection('templates').doc(template.documentId).collection('rutinas').get().toPromise().then(data => data.docs.map(d => {
      //console.log(d.data().workouts.map(w => this.videos.find(v => w == v.documentId)));
      return {
        dia: d.data().dia,
        workouts: d.data().workouts.map(w => this.videos.find(v => w == v.documentId))
      };
    }).sort((a,b) => {
      if(a.dia < b.dia) return -1;
      if(a.dia > b.dia) return 1;
      return 1;
    }));
    const dia1Filtro = this.workouts.filter(w => w.dia == '1');
    let comentarios = '';
    if(dia1Filtro.length > 0){
      const dia1 = dia1Filtro.shift();
      if(dia1.comentarios) comentarios = dia1.comentarios;
    }
    this.form.setValue({
      name: template.name,
      dia: '1',
      comentarios: comentarios
    });
    this.templateSeleccionado = template.documentId;
    $('form').removeClass('was-validated');
    $('#modal-template').modal('show');
  }
}
