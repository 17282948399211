import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { SubscriptionGuard } from './services/subscription.guard';
import { AdminGuard } from './services/admin.guard';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { ConfirmacionMailComponent } from './confirmacion-mail/confirmacion-mail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { WorkoutsComponent } from './workouts/workouts.component';
import { WorkoutComponent } from './workout/workout.component';
import { VideoComponent } from './video/video.component';
import { AgregarRecetaComponent } from './agregar-receta/agregar-receta.component';
import { RecetasComponent } from './recetas/recetas.component';
import { RecetasCategoriaComponent } from './recetas-categoria/recetas-categoria.component';
import { RecetaComponent } from './receta/receta.component';
import { ClientesComponent } from './clientes/clientes.component';
import { WorkoutsUsuarioComponent } from './workouts-usuario/workouts-usuario.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { MamacitasComponent } from './mamacitas/mamacitas.component';
import { TipsComponent } from './tips/tips.component';
import { MamacitasWorkoutsComponent } from './mamacitas-workouts/mamacitas-workouts.component';
import { AcercaDeMiComponent } from './acerca-de-mi/acerca-de-mi.component';
import { TerminosComponent } from './terminos/terminos.component';
import { MyWorkoutComponent } from './my-workout/my-workout.component';
import { CouponsComponent } from './coupons/coupons.component';
import { AvanceComponent } from './avance/avance.component';
import { CrearUsuarioVipComponent } from './crear-usuario-vip/crear-usuario-vip.component';
import { CrearTemplateComponent } from './crear-template/crear-template.component';
import { ClientesSinMigrarComponent } from './clientes-sin-migrar/clientes-sin-migrar.component';
import { SugerenciasUsuarioComponent } from './sugerencias-usuario/sugerencias-usuario.component';
import { AgregarVideoComponent } from './agregar-video/agregar-video.component';
import { FaqComponent } from './faq/faq.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ContactoComponent } from './contacto/contacto.component';
import { EditarClasesComponent } from './editar-clases/editar-clases.component';
import { EditarClaseComponent } from './editar-clase/editar-clase.component';
import { ListaClientesComponent } from './lista-clientes/lista-clientes.component';
import { Login2Component } from './login2/login2.component';
import { MantenimientoComponent } from './mantto/mantenimiento/mantenimiento.component';

const routes: Routes = [
  {
    path: 'login',
    component: Login2Component,
    pathMatch: 'full'
  },
  {
    path: 'login2',
    component: Login2Component,
    pathMatch: 'full'
  },
  {
    path: 'login/renew/:customer',
    component: Login2Component,
    pathMatch: 'full'
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'confirmacion-mail/:emailEncoded',
    component: ConfirmacionMailComponent,
    pathMatch: 'full'
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    pathMatch: 'full'
  },
  {
    path: 'workouts',
    component: WorkoutsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'workout/:id',
    component: WorkoutComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'workout/:id/:subcategory',
    component: WorkoutComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'video/:id',
    component: VideoComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'agregar-receta',
    component: AgregarRecetaComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'agregar-video',
    component: AgregarVideoComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'clientes',
    component: ClientesComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'clientes-sin-migrar',
    component: ClientesSinMigrarComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'lista-clientes',
    component: ListaClientesComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'workouts-usuario/:id',
    component: WorkoutsUsuarioComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'sugerencias-usuario',
    component: SugerenciasUsuarioComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'avance',
    component: AvanceComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'crear-usuario-vip',
    component: CrearUsuarioVipComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'crear-template',
    component: CrearTemplateComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'recetas',
    component: RecetasComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'recetas-categoria/:id',
    component: RecetasCategoriaComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'receta/:id',
    component: RecetaComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'mi-cuenta',
    component: MiCuentaComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'mamacitas',
    component: MamacitasComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'mamacitas/:categoria',
    component: MamacitasWorkoutsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },

  {
    path: 'editar-clases',
    component: EditarClasesComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'editar-clase/:id',
    component: EditarClaseComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'tips',
    component: TipsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'acerca-de-mi',
    component: AcercaDeMiComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'terminos',
    component: TerminosComponent,
    pathMatch: 'full'
  },
  {
    path: 'my-workout/:semana/:dia',
    component: MyWorkoutComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: 'faq',
    component: FaqComponent,
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    pathMatch: 'full'
  },
  {
    path: 'registro',
    component: ContactoComponent,
    pathMatch: 'full'
  },
  {
    path: 'mantto',
    component: MantenimientoComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, SubscriptionGuard]
  },
  {
    path: '**',
    component: Error404Component,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
