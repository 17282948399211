import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripeService } from './../services/stripe.service';
import { environment } from './../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
declare let $: any;

@Component({
  selector: 'app-crear-usuario-vip',
  templateUrl: './crear-usuario-vip.component.html',
  styleUrls: ['./crear-usuario-vip.component.scss']
})
export class CrearUsuarioVipComponent implements OnInit {
  public form: FormGroup;

  constructor(private stripeService: StripeService, private db: AngularFirestore) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  ngOnInit() {
  }

  public async submit(): Promise<void>{
    if(this.form.valid){
      this.form.disable();
      const { uid, error } = await fetch('https://us-central1-al-fitness.cloudfunctions.net/createUser', {
        method: 'POST',
        body: JSON.stringify(this.form.value),
        headers:{
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      }).then(response => response.json())
      .catch(error => ({error}));

      if(error){
        this.form.enable();
        $.NotificationApp.send(error.code, error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }

      const { customer, reason } = await this.stripeService.createCustomer(this.form.value.email, this.form.value.name).then(r => ({ customer: r.id, reason: null })).catch(error => ({customer: null, reason: error}));
      if(reason){
        this.form.enable();
        $.NotificationApp.send(reason.error.error.type, reason.error.error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }

      const { subscription, reason2 } = await this.stripeService.createSubscription(customer, environment.stripe.membership, '', '1624165200').then(r => ({ subscription: r.id, reason2: null })).catch(error => ({subscription: null, reason2: error}));
      if(reason2){
        this.form.enable();
        $.NotificationApp.send(reason2.error.error.type, reason2.error.error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }

      this.db.collection('users').doc(uid).set({
        customer: customer,
        email: this.form.value.email,
        subscription: subscription
      }, { merge: true }).then(() => {
        this.form.enable();
        $('form').removeClass('was-validated');
        this.form.setValue({
          email: '',
          name: '',
          password: ''
        });
        $.NotificationApp.send("Exito!", "El usuario ha sido creado correctamente.", 'bottom-right', '#06d5a1', 'success', 10000);
        return;
      }).catch(error => {
        this.form.enable();
        $.NotificationApp.send(error.code, error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      });
    }
  }

}