import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mamacitas',
  templateUrl: './mamacitas.component.html',
  styleUrls: ['./mamacitas.component.scss']
})
export class MamacitasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
