import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {
  private id: string;
  private sub: Subscription;
  public title: string;
  public url: string;
  public thumbnail: string;
  public description: string;
  public html: string;
  public cuerda : boolean;
  public pesas : boolean;
  public step : boolean;
  safeSrc: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private db: AngularFirestore, private sanitizer: DomSanitizer) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.title = '';
    this.url = '';
    this.thumbnail = '';
    this.cuerda = false;
    this.pesas =  false;
    this.step =   false;
    this.description = '';
    this.html = '';
    this.safeSrc;
  }

  

  ngOnInit() {
    this.getVideo();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getVideo(): void{
    this.sub = this.db.collection('workouts').doc<any>(this.id).valueChanges().subscribe(
      video => {
        this.title = video.title;
        this.description = video.description;
        this.url = video.url;
        this.thumbnail = video.thumbnail;
        this.html = video.html;
        this.cuerda = video.cuerda;
        this.pesas = video.pesas;
        this.step = video.step;
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/" + this.html);
      },
      error => {
        console.log(error);
      }
    );
  }
}
