import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit, OnDestroy {
  public videos: any[];
  public mainVideo: any;
  private sub: Subscription;
  public html: String;

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.getVideos();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getVideos(): void{
    this.sub = this.db.collection('tips', ref => ref.orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      videos => {
        this.videos = videos;
        const divVideos = document.getElementById('videos');
        const mainDIV = document.getElementById('mainVideo');
        const mainDIVMobile = document.getElementById('mainVideoMobile');
        let i = 0;
        videos.forEach(v => {
          i++;
          const div = document.createElement('div');
          div.classList.add('col-lg-3','mt-5');
          //div.classList.add('d-block');
          //div.classList.add('video-player');
          //div.classList.add('mb-4');
          this.html = v['html'];
          div.innerHTML = `
            <iframe class="vimeoList" style="width:100%;height:120%" src="https://player.vimeo.com/video/` + this.html + `" id="vimeo" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            <div style="  color: #000000;font-family: 'Manrope';">`+ v["title"] +`</div>`;
          if(i != 2){
            divVideos.append(div);
          } else {
            const divSpecial = document.createElement('div');
            const divSpecialMobile = document.createElement('div');
            divSpecial.classList.add('col-lg-6','offset-lg-3');
            divSpecialMobile.classList.add('col-lg-3');
            this.html = v['html'];
            divSpecial.innerHTML = `
            <iframe src="https://player.vimeo.com/video/` + this.html + `" id="vimeo" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width:800px; height:450px"></iframe>`;
            divSpecialMobile.innerHTML = `
            <iframe src="https://player.vimeo.com/video/` + this.html + `" id="vimeo" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
            mainDIV.append(divSpecial);
            mainDIVMobile.append(divSpecialMobile)
            
          }
        });
      },
      error => {
        console.log(error);
      }
    );
  }
}
