import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StripeService } from '../services/stripe.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-clientes-sin-migrar',
  templateUrl: './clientes-sin-migrar.component.html',
  styleUrls: ['./clientes-sin-migrar.component.scss']
})
export class ClientesSinMigrarComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  public clientes: any[];

  constructor(private db: AngularFirestore, private stripeService: StripeService) {
    this.clientes = [];
  }

  ngOnInit() {
    this.getClientes();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getClientes(): void{
    this.sub = this.db.collection('users', ref => ref. orderBy('email')).valueChanges({ idField: 'documentId' }).subscribe(
      clientes => {
        this.clientes = clientes.filter(c => !c['stripeAF'])
      },
      error => {
        console.log(error);
      }
    );
  }

  public async migrarStripe(cliente): Promise<void>{
    if(!cliente.stripeAF){
      const { sub, error } = await this.stripeService.getOldSubscription(cliente.subscription).then(sub => ({ sub, error: false }), reason => ({ sub: null, error: reason.error.error.message })).catch(reason => ({ sub: null, error: reason }));
      if(error){
        $.NotificationApp.send('Error', error, 'bottom-right', '#fa5c7c', 'error', 5000);
        return;
      }
      const cupon = sub.discount && sub.discount.coupon && sub.discount.coupon.id ? sub.discount.coupon.id : '';
      const current_period_end = sub.current_period_end;

      const subscription = await this.stripeService.createSubscription(sub.customer, environment.stripe.membership, cupon, current_period_end);

      this.db.collection('users').doc(cliente.documentId).set({
        subscription: subscription.id,
        stripeAF: true
      },{ merge: true });
    }
    
  }
}
