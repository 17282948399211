import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
declare var $: any;

@Component({
  selector: 'app-agregar-receta',
  templateUrl: './agregar-receta.component.html',
  styleUrls: ['./agregar-receta.component.scss']
})
export class AgregarRecetaComponent implements OnInit, AfterViewInit {
  public form: FormGroup;
  public cargando: boolean;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      ingredients: new FormControl('', [Validators.required]),
      preparation: new FormControl('', [Validators.required])
    });
    this.cargando = false;
  }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    $("#summernote-ingredients").summernote({
      placeholder:"Escribe algo...",
      //height: 230,
      callbacks: {
          onInit: e => {
              $(e.editor).find(".custom-control-description").addClass("custom-control-label").parent().removeAttr("for")
          },
          onChange: (contents, $editable) => {
            this.form.get('ingredients').setValue(contents);
          }
      }
    });
    $("#summernote-preparation").summernote({
      placeholder:"Escribe algo...",
      //height: 230,
      callbacks: {
          onInit: e => {
              $(e.editor).find(".custom-control-description").addClass("custom-control-label").parent().removeAttr("for")
          },
          onChange: (contents, $editable) => {
            this.form.get('preparation').setValue(contents);
          }
      }
    });
    //para limpiarlo es $('#summernote-ingredients').summernote('reset');
  }

  public async submit(): Promise<void>{
    const inputThumbnail: any = document.getElementById('thumbnail');
    const videoThumbnail: any = document.getElementById('video');
    if(this.form.valid && inputThumbnail.files.length > 0){
      this.cargando = true;
      $('#summernote-ingredients').summernote('disable');
      $('#summernote-preparation').summernote('disable');
      this.form.disable();
      const thumbnail: File = inputThumbnail.files[0]; 
      
      var videoTask: any;
      var video;

      if(videoThumbnail.files.length > 0){
        video: File = videoThumbnail.files[0];
        videoTask = await this.storage.ref('Recetas').child('Videos').child(this.form.value.title).put(video);
      } else {
        video = '';
        videoTask = '';
      }
      

      const thumbnailTask: any = await this.storage.ref('Thumbnails').child('Recetas').child(this.form.value.title).put(thumbnail);
      

      if(thumbnailTask.state != 'success'){
        $.NotificationApp.send("Error", 'Ocurrio un error al subir la imagen, por favor intenta de nuevo.', 'bottom-right', '#fa5c7c', 'error', 10000);
        this.cargando = false;
        $('#summernote-ingredients').summernote('enable');
        $('#summernote-preparation').summernote('enable');
        this.form.enable();
        return;
      }

      
      if(videoTask.state != 'success' && videoThumbnail.files.length > 0){
        $.NotificationApp.send("Error", 'Ocurrio un error al subir el video, por favor intenta de nuevo.', 'bottom-right', '#fa5c7c', 'error', 10000);
        this.cargando = false;
        $('#summernote-ingredients').summernote('enable');
        $('#summernote-preparation').summernote('enable');
        this.form.enable();
        return;
      }
      
      

      const thumbnailUrl: any = await thumbnailTask.task.snapshot.ref.getDownloadURL();

      var videoUrl: any = '';
      if(videoThumbnail.files.length > 0){
        videoUrl = await videoTask.task.snapshot.ref.getDownloadURL();
      } else {
        videoUrl = '';
      }

      await this.db.collection('recetas').add({
        category: this.form.value.category,
        ingredients: this.form.value.ingredients,
        preparation: this.form.value.preparation,
        thumbnail: thumbnailUrl,
        title: this.form.value.title,
        video: videoUrl
      });

      this.cargando = false;
      $('#summernote-ingredients').summernote('enable');
      $('#summernote-preparation').summernote('enable');
      this.form.enable();
      this.form.reset('');
      $('#summernote-ingredients').summernote('reset');
      $('#summernote-preparation').summernote('reset');
      inputThumbnail.value = '';
      videoThumbnail.value = '';
      $('.was-validated').removeClass('was-validated');
      $.NotificationApp.send("Exito!", "La receta ha sido creada correctamente.", 'bottom-right', '#06d5a1', 'success', 10000);
    } else {
      console.log('Missing field');
    }
    
  }
}
