import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $ :any;

@Component({
  selector: 'app-confirmacion-mail',
  templateUrl: './confirmacion-mail.component.html',
  styleUrls: ['./confirmacion-mail.component.scss']
})
export class ConfirmacionMailComponent implements OnInit, AfterViewInit, OnDestroy {
  public email: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.email = '';
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if(params['emailEncoded']){
        this.email = this.decode(params['emailEncoded']);
      }
    });
  }

  ngAfterViewInit(): void {
    document.body.classList.add("authentication-bg");
  }
  ngOnDestroy(): void {
    document.body.classList.remove("authentication-bg");
  }

  private decode(str: string): any{
    let encodedString = '';
    for(const char of str){
      encodedString += String.fromCharCode(char.charCodeAt(0) - str.length);
    }

    return encodedString;
  }

  public submit(){
    this.router.navigate(['/login']);
  }

}
