import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private user: firebase.User;
  private headers;

  constructor(private http: HttpClient, private db: AngularFirestore, private afAuth: AngularFireAuth) {
  }

  public async createCustomer(email: string, name: string, source = ''): Promise<any>{
    const params: string = `email=${email}&name=${name}&description=${name}${source ? `&source=${source}` : ''}`;
    //Al crear un cliente no hay usuario, pero siempre debe ser con la llaves nuevas
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKeyAF}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    
    return this.http.post(`${environment.stripe.baseUri}customers`, params,  { headers: this.headers }).toPromise();
  }

  public async createSubscription(customer: string, plan: string, cupon: string, trial_end = '', proration_behavior = 'none'): Promise<any>{
    let params: string = `customer=${customer}&items[0][plan]=${plan}`;
    if(cupon) params += `&coupon=${cupon}`;
    if(trial_end) params += `&trial_end=${trial_end}&proration_behavior=${proration_behavior}`;
    //Al crear suscripcion no hay usuario, pero siempre debe ser con la llaves nuevas
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKeyAF}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return this.http.post(`${environment.stripe.baseUri}subscriptions`, params,  { headers: this.headers }).toPromise();
  }

  public async createFreeTrial(subscription: string, trialEnd: string): Promise<any>{
    const params: string = `trial_end=${trialEnd}&proration_behavior=none`;
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    return this.http.post(`${environment.stripe.baseUri}subscriptions/${subscription}`, params, { headers: this.headers }).toPromise();
  }

  public async getSubscription(id: string): Promise<any>{
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }
    return this.http.get(`${environment.stripe.baseUri}subscriptions/${id}`, { headers: this.headers }).toPromise();
  }
  
  public async getOldSubscription(id: string): Promise<any>{
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKey}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return this.http.get(`${environment.stripe.baseUri}subscriptions/${id}`, { headers: this.headers }).toPromise();
  }

  public async validateCoupon(id: string): Promise<any>{
    //Al validar cupon en el registro no hay usuario, pero siempre debe ser con la llaves nuevas
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKeyAF}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return this.http.get(`${environment.stripe.baseUri}coupons/${id}`, { headers: this.headers }).toPromise();
  }

  public async cancelAutomaticRenewal(subscription: string): Promise<any>{
    const params: string = `cancel_at_period_end=true`;
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    return this.http.post(`${environment.stripe.baseUri}subscriptions/${subscription}`, params, { headers: this.headers }).toPromise();
  }

  public async activateAutomaticRenewal(subscription: string): Promise<any>{
    const params: string = `cancel_at_period_end=false`;
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    return this.http.post(`${environment.stripe.baseUri}subscriptions/${subscription}`, params, { headers: this.headers }).toPromise();
  }

  public async getCoupons(): Promise<any>{
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKeyAF}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    return this.http.get(`${environment.stripe.baseUri}promotion_codes`, { headers: this.headers, params: { limit: '100' } }).toPromise();
  }

  public async createCoupon(id, name, amount_off): Promise<any>{
    const params: string = `amount_off=${amount_off}&duration=forever&currency=usd&name=${name}&id=${id}`;
    this.headers = {
      'Authorization': `Bearer ${environment.stripe.secretKeyAF}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    };


    return this.http.post(`${environment.stripe.baseUri}coupons`, params, { headers: this.headers }).toPromise();
  }

  public async customerPortal(customer, return_url): Promise<any>{
    const params: string = `customer=${customer}&return_url=${return_url}`;
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }

    return this.http.post(`${environment.stripe.baseUri}billing_portal/sessions`, params, { headers: this.headers }).toPromise();
  }

  public async getCustomer(customer): Promise<any>{
    this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
      this.headers = {
        'Authorization': `Bearer ${u && u.stripeAF ? environment.stripe.secretKeyAF : environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    } else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }
    return this.http.get(`${environment.stripe.baseUri}customers/${customer}`, { headers: this.headers }).toPromise();
  }

  public async addCardToCustomer(customer, token): Promise<any>{
    const params: string = `source=${token}`;
    /*this.user = this.afAuth.auth.currentUser;
    if(this.user){
      const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());*/
      this.headers = {
        'Authorization': `Bearer ${/*u && u.stripeAF ? */environment.stripe.secretKeyAF/* : environment.stripe.secretKey*/}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    /*} else{
      this.headers = {
        'Authorization': `Bearer ${environment.stripe.secretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }*/
    return this.http.post(`${environment.stripe.baseUri}customers/${customer}`, params, { headers: this.headers }).toPromise();
  }
}
