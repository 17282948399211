import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recetas-categoria',
  templateUrl: './recetas-categoria.component.html',
  styleUrls: ['./recetas-categoria.component.scss']
})
export class RecetasCategoriaComponent implements OnInit {
  private id: string;
  public recetas: any[];
  public subsection: any;
  private sub: Subscription;

  constructor(private route: ActivatedRoute, private db: AngularFirestore) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.id = params['id']
        this.getRecetas();
      }
    )
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getRecetas(): void{
    let url = (window.location.href).split('/');
    this.subsection = url[url.length - 1];
    this.sub = this.db.collection('recetas', ref => ref.where('category', '==', this.id).orderBy('video', 'desc')).valueChanges({ idField: 'documentId' }).subscribe(
      recetas => {
        this.recetas = recetas;
      },
      error => {
        console.log(error);
      }
    );
  }

}
