import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workouts',
  templateUrl: './workouts.component.html',
  styleUrls: ['./workouts.component.scss']
})
export class WorkoutsComponent implements OnInit {
  public cardio: any[];
  public fuerza: any[];
  public pro: any[];

  constructor() {
    /*this.categories = [
      {
        id: '11',
        name: 'Parte Superior'
      },
      {
        id: '12',
        name: 'Abs & Core'
      },
      {
        id: '13',
        name: 'Piernas & Pompis'
      },
      {
        id: '14',
        name: 'Full Body'
      }
    ];*/
    this.cardio = [
      { id: '11', name: 'Superior' },
      { id: '12', name: 'Abs & Core' },
      { id: '13', name: 'Piernas & Pompis' },
      { id: '14', name: 'Full' }
    ];
    this.fuerza = [
      { id: '21', name: 'Superior' },
      { id: '22', name: 'Abs & Core' },
      { id: '23', name: 'Piernas & Pompis' },
      { id: '24', name: 'Full' }
    ];
    this.pro = [
      { id: '31', name: 'Pre-Clase' },
      { id: '32', name: 'Post-Clase' },
      { id: '33', name: 'Bonus' }
    ];
  }

  ngOnInit() {
  }

}
