import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-acerca-de-mi',
  templateUrl: './acerca-de-mi.component.html',
  styleUrls: ['./acerca-de-mi.component.scss']
})
export class AcercaDeMiComponent implements OnInit, OnDestroy {
  public videos: any[];
  private sub: Subscription;

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.getVideos();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getVideos(): void{
    this.sub = this.db.collection('acercademi', ref => ref.orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      videos => {
        this.videos = videos;
      },
      error => {
        console.log(error);
      }
    );
  }
}
