import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-receta',
  templateUrl: './receta.component.html',
  styleUrls: ['./receta.component.scss']
})
export class RecetaComponent implements OnInit, OnDestroy {
  private id: string;
  private sub: Subscription;
  public title: string;
  public video: string;
  public ingredients: string;
  public thumbnail: string;
  public description: string;
  public preparation: string;
  //public dacast: string;
  public html: string;
  safeSrc: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private db: AngularFirestore, private sanitizer: DomSanitizer) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.title = '';
    this.video = '';
    this.ingredients = '';
    this.thumbnail = '';
    this.description = '';
    this.preparation = '';
    //this.dacast = '';
    this.html = '';
    this.safeSrc;
  }

  

  ngOnInit() {
    this.getReceta();
    
  }

  ngAfterViewInit() {
   // this.getDacast();this.getDacast();
   //this.getDacast();
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getReceta(): void{
    this.sub = this.db.collection('recetas').doc<any>(this.id).valueChanges().subscribe(
      receta => {
        this.title = receta.title;
        this.description = receta.description;
        this.preparation = receta.preparation;
        this.video = receta.video;
        this.ingredients = receta.ingredients;
        this.thumbnail = receta.thumbnail;
        //this.dacast = receta.dacast;
        this.html = receta.html;
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/" + this.html);
      },
      error => {
        console.log(error);
      }
    );
  }

  /*
  private getDacast(): void{
    const video: any = document.createElement("script");
    video.id = 'e52d8678-e7d6-fdac-65af-6f3d6260f717-vod-7ac4ac0b-93b4-d02f-b09c-f9160534f22c';
    video.style.width = '100%';
    video.style.height = '100%';
    video.classList.add('dacast-video');
    video.src = '//player.dacast.com/js/player.js';
    const player: any = document.getElementById("dacast");
    player.append(video);
  }
  */

}
