import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { StripeService } from '../services/stripe.service';
import { User } from '../services/user';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  public isAdmin: boolean;
  private sub: Subscription;
  public user: User;
  public clientes: any[];
  public semana: number;

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private stripeService: StripeService) {
    //this.isAdmin = environment.admins.indexOf(this.user.email) > -1;
    this.clientes = [];
    this.semana = 0;
  }

  ngOnInit() {
    this.getSemana();
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }

  private getClientes(): void {
    this.sub = this.db.collection('users', ref => ref.orderBy('email')).valueChanges({ idField: 'documentId' }).subscribe(
      clientes => {
        this.clientes = clientes.map(async (c: any) => {
          c.sinRutinaNunca = await this.db.collection('users').doc(c.documentId).collection('rutina').get().toPromise().then(s => s.docs.length == 0);
          c.sinRutina = await this.db.collection('users').doc(c.documentId).collection('rutina', ref => ref.where('semana', '==', `${this.semana}`.padStart(2, '0'))).get().toPromise().then(s => s.docs.length == 0);
          c.status = await this.stripeService.getSubscription(c.subscription).then(s => s.status).catch(r => 'test');
          console.log(c.email);
          //console.log(c.status);

          return c;
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  private getSemana(): void {
    fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json()).then(response => {
      this.semana = response.semana + 1;
      this.getClientes();
    });
  }
}
