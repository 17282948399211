import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../services/user';
import { environment } from '../../environments/environment';
declare var $ :any;

@Component({
  selector: 'app-leftside-menu',
  templateUrl: './leftside-menu.component.html',
  styleUrls: ['./leftside-menu.component.scss']
})
export class LeftsideMenuComponent implements OnInit, AfterViewInit {
  private user: User;
  public isAdmin: boolean;
  public selected: string;
  constructor(private afAuth: AngularFireAuth) {
    this.user = this.afAuth.auth.currentUser;
    this.isAdmin = environment.admins.indexOf(this.user.email) > -1;
  }

  ngOnInit() {
    this.tabSelected()
  }

  ngAfterViewInit(): void {
    $.App.init();
  }

  public tabSelected(){
    let url = (window.location.href).split('/');
    let recetas = ['platillos','sides','desayunos','postres'];
    let mamacitas = ['embarazo','postparto'];
    let workouts = ['cardio','pro','fuerza'];
    if( recetas.includes(url[url.length - 1]) ){
      this.selected = 'recetas';
    } else if( mamacitas.includes(url[url.length - 1]) ){
      this.selected = 'mamacitas';
    } else if( workouts.includes(url[url.length - 1])) {  
      this.selected = 'workouts';
    } else {
      this.selected = url[url.length - 1] == '' ? 'home' : url[url.length - 1];
    }
  }
}
