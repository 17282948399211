import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { VimeoService } from './../services/vimeo.service';
import { Subscription } from 'rxjs';
import { $ } from 'protractor';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var jQuery:any;

@Component({
  selector: 'app-mamacitas-workouts',
  templateUrl: './mamacitas-workouts.component.html',
  styleUrls: ['./mamacitas-workouts.component.scss']
})
export class MamacitasWorkoutsComponent implements OnInit, OnDestroy {
  public categoria: string;
  public videos: any[];
  public videoSelected:any;
  private access_token:any;
  private sub: Subscription;

  constructor(
    private route: ActivatedRoute, 
    private db: AngularFirestore, 
    private sanitizer: DomSanitizer,
    private vimeo: VimeoService
  ) {
    this.videoSelected = {};
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.categoria = params['categoria']
        this.getVideos();
      }
    )
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getVideos(): void{
    this.sub = this.db.collection('workouts', ref => ref.where('category', '==', this.categoria).orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      videos => {
        this.videos = videos;
        this.videos.forEach( (video) => {
          this.vimeo.getThumbnail(video.html).subscribe(
            response => {
              video.thumbnail = response.data[0].sizes[4].link_with_play_button
            },
            error => {
              console.log(error)
            }
          );
          //video.thumbnail = video.thumbnail
          video.title = video.title;
          video.description = video.description;
          video.url = video.url;
          video.html = video.html;
          video.cuerda = video.cuerda;
          video.pesas = video.pesas;
          video.step = video.step;
          video.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/" + video.html);
        })
        console.log(this.videos)
      },
      error => {
        console.log(error);
      }
    );
  }

  public selectVideo(id:any){
    let index:any = this.videos.findIndex( v => id === v.documentId)
    if(index != -1){
      this.videoSelected = this.videos[index];
      console.log(this.videoSelected)
      jQuery('#video-modal').modal('show');
    }
  }

  public changeImg(video:any){
    video.img_loaded = true;
    console.log(video)
  }
}
