// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDKm2YS0jny7_09VPGa3gRX9dKcNNrSOyc',
    authDomain: 'al-fitness.firebaseapp.com',
    databaseURL: 'https://al-fitness.firebaseio.com',
    projectId: 'al-fitness',
    storageBucket: 'al-fitness.appspot.com',
    messagingSenderId: '497191072327',
    appId: '1:497191072327:web:723bae90a4ff5c982d7762',
    measurementId: 'G-XZEZTCS72L'
  },
  stripe: {
    baseUri: 'https://api.stripe.com/v1/',
    publishableKey: 'pk_test_i66HqWSsMIVZ0ryLaNs04IRk00lwOQgBp5',
    secretKey: 'sk_test_ttNJXqR8OZhYrupniI4K89MX00MnKn0Pvc',
    membership: 'plan_HGgQYhNek5awQd',
    publishableKeyAF: 'pk_live_OVDqHHuqM4r9m3EwELLOFzdG00jXwJk5al',
    secretKeyAF: 'sk_live_qB7CwbcfFYUzbF4BNQi9ZJj500siu42LBr'
  },
  //stripe: {
  //  baseUri: 'https://api.stripe.com/v1/',
  //  publishableKey: 'pk_live_2DqOyvllDKOFJZLoa4xq9uoA',
  //  secretKey: 'sk_live_dQbBPhx1fogghWCKDrVSVek1',
  //  membership: 'prod_HGgO4yileR9v6R'
  //},
  admins: [
    'alex@vende.io',
    'mauricio@vende.io',
    'mauricioramosceja@hotmail.com',
    'andrealaveagav@gmail.com'
  ]
};*/

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDKm2YS0jny7_09VPGa3gRX9dKcNNrSOyc',
    authDomain: 'al-fitness.firebaseapp.com',
    databaseURL: 'https://al-fitness.firebaseio.com',
    projectId: 'al-fitness',
    storageBucket: 'al-fitness.appspot.com',
    messagingSenderId: '497191072327',
    appId: '1:497191072327:web:723bae90a4ff5c982d7762',
    measurementId: 'G-XZEZTCS72L'
  },
  stripe: {
    baseUri: 'https://api.stripe.com/v1/',
    publishableKey: 'pk_live_2DqOyvllDKOFJZLoa4xq9uoA',
    secretKey: 'sk_live_dQbBPhx1fogghWCKDrVSVek1',
    membership: 'price_1Hosx6KEgIYCTbgIK5Wkbzx0',
    membershipForAYear: 'price_1Ib5rDKEgIYCTbgIC7UrLYEq',
    publishableKeyAF: 'pk_live_OVDqHHuqM4r9m3EwELLOFzdG00jXwJk5al',
    secretKeyAF: 'sk_live_qB7CwbcfFYUzbF4BNQi9ZJj500siu42LBr'
  },
  admins: [
    'alex@vende.io',
    'mauricio@vende.io',
    'andrealaveagav@gmail.com'
  ]
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
