import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../services/user';
import { StripeService } from '../services/stripe.service';
import { parse } from 'querystring';




@Component({
  selector: 'app-lista-clientes',
  templateUrl: './lista-clientes.component.html',
  styleUrls: ['./lista-clientes.component.scss']
})
export class ListaClientesComponent implements OnInit {
  public isAdmin: boolean;
  public user: User;
  public clientes: any[];
  public clientesClase: any[];
  public semana: number;
  public year: number;


  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private stripeService: StripeService) {
    this.clientes = [];
    this.clientesClase = [];
    this.semana = 0;
    this.year = 0;
  }

  ngOnInit() {
    this.getSemana();
    this.getClientes();

  }

  private getSemana(): void {
    fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json()).then(response => {
      this.semana = parseInt(response.semana);
      this.year = parseInt(response.year);
      console.log('Server Week: ',response);
     // this.semana = this.getCurrentWeek();
      console.log('Server Week: ',this.semana)
      console.log('Current Week: ', this.getCurrentWeek());
    });
  }

  private getCurrentWeek(): number {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const dayOfWeek = start.getDay();
    const weekNum = Math.ceil((dayOfYear + (dayOfWeek === 0 ? 6 : dayOfWeek - 1)) / 7);
    return weekNum;
  }

 


  async getClientes() {
    this.db.collection('users', ref => ref.orderBy('email')).valueChanges({ idField: 'documentId' }).subscribe(
      async clientes => {
        let promises: any = clientes.map(async (c: any) => {
          c.status = await this.stripeService.getSubscription(c.subscription).then(s => s.status).catch(r => 'test');
          return new Promise((resolve, reject) => { resolve(c) });
        });
        Promise.all(promises).then(
          result => {
            this.clientes = result;
            this.clientes.forEach(cliente => {
              cliente.semana = parseInt(cliente.semana);
              if (cliente.semana == this.semana + 1) {
                cliente.conClase = true;
              } else {
                cliente.conClase = false;
              }
            });
            //console.log('Clientes: ', this.clientes);
          })
      },
      error => {
        console.log(error);
      }
    );
  }

  async eliminarCliente(cliente: any) {
    this.db.collection('users').doc(cliente.documentId).delete().then(() => {
      console.log('customer deleted')
    })
    const xhr = new XMLHttpRequest();
    const data = {
      uid: cliente.documentId
    };
    xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/deleteAccount');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(data));

  }

  private async deleteOld() {
    console.log("Delete Old");
    // console.log('Clientes: ', this.clientes);
    let numRutinas = 0;
    this.clientes.forEach(c => {
      console.log('Cliente: ', c.email);
      // console.log(c.documentId);
      this.db.collection('users').doc(c.documentId).collection('rutina', ref => ref
      .where('year', "==", '2023')
      .where('semana', "not-in", ['50','51','52','53'])
     
      ).get().subscribe(
        async rutinas => {
          let batch = this.db.firestore.batch(); // Create a new batch instance
          rutinas.forEach(doc => {
            numRutinas++;
            let rutina = doc.data();
            console.log(numRutinas, ' -  Rutina ', rutina.year, ' - ', doc.id, ' - ');
            
            if (numRutinas < 100) {
              console.log('Borrando: ', rutina.year, ' - ', doc.id, ' - ');
              let docRef = this.db.collection('users').doc(c.documentId).collection('rutina').doc(doc.id).ref;
              batch.delete(docRef); // Add the delete operation to the batch
              
            } else if(numRutinas % 100 === 0) {
              batch.commit();
              batch = this.db.firestore.batch(); // Create a new batch for the next operations
              numRutinas = 0;
            }else {
              console.log('No se borro nada. Rutinas: ', numRutinas);
            }
            

          })

            await batch.commit();



        }
      );
    })




     



 




  }

  private async deleteRepeated(){
    console.log("Delete Repeated");
    // console.log('Clientes: ', this.clientes);
    let numRutinas = 0;
    this.clientes.forEach(c => {
      console.log('Cliente: ', c.email);
      // console.log(c.documentId);
      this.db.collection('users').doc(c.documentId).collection('rutina', ref => ref
      .where('year', "==", '2023')
      .where('semana', "==", '53')
      .where('repetida', "==", true)
     
      ).get().subscribe(
        async rutinas => {
          let batch = this.db.firestore.batch(); // Create a new batch instance
          rutinas.forEach(doc => {
            console.log('Num Rutinas: ', numRutinas);
            numRutinas++;
            let rutina = doc.data();
            console.log(numRutinas, ' -  Rutina ', rutina.year, ' - ', doc.id, ' - ');
            
            if (numRutinas < 100) {
              console.log('Borrando: ', rutina.year, ' - ', doc.id, ' - ');
              let docRef = this.db.collection('users').doc(c.documentId).collection('rutina').doc(doc.id).ref;
              batch.delete(docRef); // Add the delete operation to the batch
              
            } else if(numRutinas % 100 === 0) {
              batch.commit();
              batch = this.db.firestore.batch(); // Create a new batch for the next operations
              numRutinas = 0;
            }else {
              console.log('No se borro nada. Rutinas: ', numRutinas);
            }
            

          })
            await batch.commit();

        }
      );
    })




     

  }

}
