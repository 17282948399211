import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { VimeoService } from './../services/vimeo.service';

@Component({
  selector: 'app-workout',
  templateUrl: './workout.component.html',
  styleUrls: ['./workout.component.scss']
})
export class WorkoutComponent implements OnInit, OnDestroy {
  public id: string;
  public  videos: any[];
  private sub: Subscription;
  public videosSegmentados: any;
  private subcategory: string;

  constructor(
    private route: ActivatedRoute, 
    private db: AngularFirestore,
    private vimeoService:VimeoService
  ) {
    this.videosSegmentados = {
      'abs' : [],
      'full': [],
      'piernas': [],
      'superior': []
    }
  }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.videosSegmentados = {
          'abs' : [],
          'full': [],
          'piernas': [],
          'superior': []
        }
        this.subcategory = params['subcategory'];
        this.id = params['id']
        if(this.subcategory == null){
          this.getVideos() 
        } else {
          let section = '';
          switch(this.subcategory){
            case 'superior': 
              section = 'Superior'; 
              this.getVideosBySubcategory(section);
              break;
            case 'abs': 
              section = 'Abs & Core'; 
              this.getVideosBySubcategory(section);
              break;
            case 'piernas': 
              section = 'Piernas & Pompis'; 
              this.getVideosBySubcategory(section);
              break;
            case 'full': 
              section = 'Full';
              this.getVideosBySubcategory(section);
              break;
            default: 
              this.getVideos(); 
              break;
          }
        }
      }
    )
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
  }

  private getVideos(): void{
    this.sub = this.db.collection('workouts', ref => ref.where('category', '==', this.id).orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      videos => {
        this.videos = videos;
        this.videos.forEach( (video) => {
          this.vimeoService.getThumbnail(video.html).subscribe(
            response => {
              video.portada = response.data[0].sizes[4].link_with_play_button
            },
            error => {
              console.log(error)
            }
          );
          video.title = video.title;
          video.description = video.description;
          video.url = video.url;
          video.html = video.html;
          video.cuerda = video.cuerda;
          video.pesas = video.pesas;
          video.step = video.step
          
          if(this.id != 'pro'){
            if(video.seccion){
              if(video.seccion == 'Abs & Core'){ this.videosSegmentados.abs.push(video) } 
              if(video.seccion == 'Full'){ this.videosSegmentados.full.push(video) } 
              if(video.seccion == 'Piernas & Pompis'){ this.videosSegmentados.piernas.push(video) } 
              if(video.seccion == 'Superior'){ this.videosSegmentados.superior.push(video) } 
            }
          }
          //console.log(this.videosSegmentados)
        });  
      },
      error => {
        console.log(error);
      }
    );
  }

  private getVideosBySubcategory(section): void{
    this.sub = this.db.collection('workouts', ref => ref.where('category', '==', this.id).where('seccion','==',section).orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      videos => {
        this.videos = videos;
        console.log(this.videos)
      },
      error => {
        console.log(error);
      }
    );
  }

  public changeImg(video:any){
    video.img_loaded = true;
    console.log(video)
  }
}
