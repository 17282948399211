import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;

  constructor(private auth: AuthService, private afAuth: AngularFireAuth, private router: Router) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit() {
    this.afAuth.auth.signOut();
  }

  ngAfterViewInit(): void {
		document.body.classList.add("authentication-bg");
	}

	ngOnDestroy(): void {
		document.body.classList.remove("authentication-bg");
	}

  public submit(e){
    e.target.classList.add('was-validated');
    if(this.form.valid){
      this.auth.resetPassword(this.form.value.email).then(
        () => {
          this.router.navigate(['/confirmacion-mail', this.encode(this.form.value.email)]);
        },
        reason => {
          $.NotificationApp.send(reason.code, reason.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        }
      );
    }
  }

  private encode(str: string): any{
    let encodedString = '';
    for(const char of str){
      encodedString += String.fromCharCode(char.charCodeAt(0) + str.length);
    }

    return encodedString;
  }
}
