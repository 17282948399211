import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-workouts-usuario',
  templateUrl: './workouts-usuario.component.html',
  styleUrls: ['./workouts-usuario.component.scss']
})
export class WorkoutsUsuarioComponent implements OnInit, OnDestroy {
  private uid: string;
  public user: any;
  public semanas: any[];
  private sub: any;
  private sub2: any;
  public form: FormGroup;
  public formTemplate: FormGroup;
  public categorias: string[];
  public categoriaSeleccionada: string;
  public videos: any[];
  public videoSeleccionado: string;
  public videosSeleccionados: any[];
  public templates: any[];
  public rutinaSeleccionada: string;
  public tipo: string;
  public notas: string;

  constructor(private db: AngularFirestore, private route: ActivatedRoute) {
    this.uid = this.route.snapshot.paramMap.get('id');
    this.semanas = [];
    this.form = new FormGroup({
      semana: new FormControl('', [Validators.required]),
      dia: new FormControl('1', [Validators.required]),
      comentarios: new FormControl('', [])
    });
    this.formTemplate = new FormGroup({
      semana: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
      template: new FormControl('', [Validators.required])
    });
    this.categorias = [];
    this.categoriaSeleccionada = '';
    this.videos = [];
    this.videoSeleccionado = '';
    this.videosSeleccionados = [];
    this.templates = [];
    this.rutinaSeleccionada = '';
    this.tipo = '';
    this.notas = '';
  }


  ngOnInit() {
    this.getUser();
    this.getRutinas();
    this.getVideos();
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  private async getUser(): Promise<void> {
    this.user = await this.db.collection('users').doc(this.uid).get().toPromise().then(doc => doc.data());

    if (this.user.type) this.tipo = this.user.type;
    if (this.user.notas) this.notas = this.user.notas;
  }

  private getRutinas(): void {
    this.sub = this.db.collection('users').doc(this.uid).collection('rutina', ref => ref.orderBy('semana')).valueChanges({ idField: 'documentId' }).subscribe(
      rutinas => {
        this.semanas = [];
        rutinas.forEach(r => {
          const index = this.semanas.findIndex(s => s.numero == r.semana);
          if (index == -1) {
            this.semanas.push({
              numero: r.semana,
              comentarios: '',
              dias: [{
                numero: r.dia,
                year: r.year || '2020',
                documentId: r.documentId,
                workouts: r.workouts.map(async w => await this.db.collection('workouts').doc(w).get().toPromise().then(doc => { let data = doc.data(); data.documentId = doc.id; return data; }))
              }]
            });
          } else {
            this.semanas[index].dias.push({
              numero: r.dia,
              year: r.year || '2020',
              documentId: r.documentId,
              workouts: r.workouts.map(async w => await this.db.collection('workouts').doc(w).get().toPromise().then(doc => { let data = doc.data(); data.documentId = doc.id; return data; }))
            });
            this.semanas[index].dias.sort((a, b) => {
              if (a.numero < b.numero) return -1;
              if (a.numero > b.numero) return 1;

              return 0;
            });
          }
          const indexComentario = this.semanas.findIndex(s => s.numero == r.semana);
          if (r.dia == '1') this.semanas[indexComentario].comentarios = r.comentarios || '';
        });
      },
      error => {
        console.log(error);
      }
    );
  }

  public eliminarRutina(id: string): void {
    this.db.collection('users').doc(this.uid).collection('rutina').doc(id).delete();
  }

  private getVideos(): void {
    this.sub2 = this.db.collection('workouts', ref => ref.orderBy('category').orderBy('title')).valueChanges({ idField: 'documentId' }).subscribe(
      (videos: any) => {
        this.categorias = [];
        videos.forEach(v => {
          if (this.categorias.indexOf(v.category) == -1) this.categorias.push(v.category);
        });
        this.videos = videos;
        this.getTemplates();
      },
      error => {
        console.log(error);
      }
    );
  }

  public modalAgregarRutina(): void {
    this.videosSeleccionados = [];
    this.videoSeleccionado = '';
    this.categoriaSeleccionada = '';
    this.rutinaSeleccionada = '';
    this.form.setValue({
      semana: '',
      dia: '1',
      comentarios: ''
    });
    $('form').removeClass('was-validated');
    $('#modal-workout').modal('show');
  }

  public modalTemplates(): void {
    this.formTemplate.setValue({
      semana: '',
      template: ''
    });
    $('form').removeClass('was-validated');
    $('#modal-template').modal('show');
  }

  public videosPorCategoria(): any[] {
    return this.videos.filter(v => v.category == this.categoriaSeleccionada);
  }

  public agregarVideo(): void {
    if (this.videoSeleccionado) {
      const index = this.videos.findIndex(v => v.documentId == this.videoSeleccionado);
      this.videosSeleccionados.push(this.videos[index]);
      this.videoSeleccionado = '';
      this.categoriaSeleccionada = '';
    }
  }

  public submit(): void {
    if (this.form.valid && this.videosSeleccionados.length > 0) {
      const week: string[] = this.form.value.semana.split('-');
      const semana = week.pop().replace(/[Ww]/g, '');
      const year = week.pop();
      if (this.rutinaSeleccionada) {// Editar rutina 
        this.db.collection('users').doc(this.uid).collection('rutina').doc(this.rutinaSeleccionada).set({
          dia: this.form.value.dia,
          semana: semana,
          year: year,
          workouts: this.videosSeleccionados.map(v => v.documentId),
          comentarios: this.form.value.comentarios
        }, { merge: true }).then(

          () => {
            $('#modal-workout').modal('hide');
            this.db.collection('users').doc(this.uid).set({
              semana: semana
            }, { merge: true });
          },
          reason => {
            console.log(reason);
          }
        );
      } else {// Crear rutina
        this.db.collection('users').doc(this.uid).collection('rutina').add({
          dia: this.form.value.dia,
          semana: semana,
          year: year,
          workouts: this.videosSeleccionados.map(v => v.documentId),
          comentarios: this.form.value.comentarios
        }).then(
          () => {
            this.db.collection('users').doc(this.uid).set({
              semana: semana
            }, { merge: true });
            $('#modal-workout').modal('hide');
          },
          reason => {
            console.log(reason);
          }
        );
      }
    } else {
      console.log(this.form.valid, this.videosSeleccionados.length)
    }
  }

  public async getTemplates(): Promise<void> {
    this.db.collection('templates', ref => ref.orderBy('name')).valueChanges({ idField: 'documentId' }).subscribe(
      (templates: any) => {
        templates.sort((a, b) => {
          let txtA = a.name.match(/[a-zA-Z\s]+/).shift().trim().toLowerCase();
          let txtB = b.name.match(/[a-zA-Z\s]+/).shift().trim().toLowerCase();

          if (txtA > txtB) return 1;
          if (txtA < txtB) return -1;

          let numA = parseInt(a.name.match(/[\d]+/) || ['0']);
          let numB = parseInt(b.name.match(/[\d]+/) || ['0']);

          if (numA > numB) return 1;
          if (numA < numB) return -1;

          return 0;
        }).forEach(async (template, i) => {
          let rutinas = await this.db.collection('templates').doc(template.documentId).collection('rutinas').get().toPromise().then(data => data.docs.map(d => d.data()));
          rutinas = rutinas.map(rutina => {
            rutina.workouts = rutina.workouts.map(workout => {
              const video = this.videos.find(v => v.documentId == workout);

              return video || '';
            });

            return rutina;
          });
          templates[i].rutinas = rutinas.sort((a, b) => {
            if (a.dia > b.dia) return 1;
            if (a.dia < b.dia) return -1;
            return 0;
          });

        });
        this.templates = templates;
      },
      error => {
        console.log(error);
      }
    );
  }

  public submitTemplate(): void {
    if (this.formTemplate.valid) {
      const template = this.templates.find(t => t.documentId == this.formTemplate.value.template);
      const week: string[] = this.formTemplate.value.semana.split('-');
      const semana = week.pop().replace(/[Ww]/g, '');
      const year = week.pop();
      const rutinas = template.rutinas.map(rutina => {
        rutina.workouts = rutina.workouts.map(w => w.documentId);
        rutina.semana = semana;
        rutina.year = year;

        return this.db.collection('users').doc(this.uid).collection('rutina').add(rutina);
      });
      Promise.all(rutinas).then(() => {
        this.db.collection('users').doc(this.uid).set({
          semana: semana
        }, { merge: true });
        $('#modal-template').modal('hide');
      });
    }
  }

  public editarRutina(dia, semana, comentarios): void {
    console.log(dia.year);
    this.rutinaSeleccionada = dia.documentId;
    this.videosSeleccionados = dia.workouts.map(w => w['__zone_symbol__value']);
    this.videoSeleccionado = '';
    this.categoriaSeleccionada = '';

    this.form.setValue({
      semana: `${dia.year}-W${semana}`,
      dia: dia.numero,
      comentarios: dia.numero == '1' ? comentarios : ''
    });
    $('form').removeClass('was-validated');
    $('#modal-workout').modal('show');
  }

  public eliminarWorkout(video): void {
    const index = this.videosSeleccionados.findIndex(v => v.documentId == video.documentId);
    if (index > -1) {
      this.videosSeleccionados.splice(index, 1);
    }
  }

  public guardarTipoDeUsuario(): void {
    this.db.collection('users').doc(this.uid).set({
      type: this.tipo
    }, { merge: true });
  }

  public guardarNotas(): void {
    this.db.collection('users').doc(this.uid).set({ notas: this.notas }, { merge: true });
  }
}
