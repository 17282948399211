import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { StripeService } from './../services/stripe.service'; 
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
  public coupons: any[];
  public form: FormGroup;

  constructor(private stripeService: StripeService) {
    this.coupons = [];
    this.form = new FormGroup({
      id: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      amount_off: new FormControl('', [Validators.required, Validators.min(1)])
    });
  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.getCoupons();
  }

  private getCoupons(): void{
    this.stripeService.getCoupons().then(
      response => {
        /**
         * id
         * name
         * amount_off
         * times_redeemed
         * created
         */

        this.coupons = response.data.map(c => ({
          id: c.id,
          code: c.code,
          name: c.coupon.name,
          amount_off: c.coupon.amount_off,
          times_redeemed: c.times_redeemed,
          created: c.created
        })).sort((a,b) => {
          if(a.created < b.created) return -1;
          if(a.created > b.created) return 1;

          return 0;
        });
      },
      reason => {
        $.NotificationApp.send('Error', reason.error.error.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      }
    );
  }

  public modalCupon(): void{
    this.form.setValue({
      id:'',
      name:'',
      amount_off:''
    });
    $('form').removeClass('was-validated');
    $('#crear-cupon').modal('show');
  }

  public submit(): void{
    if(this.form.valid){
      this.stripeService.createCoupon(this.form.value.id, this.form.value.name, this.form.value.amount_off*100).then(
        response => {
          this.getCoupons();
          $('#crear-cupon').modal('hide');
        },
        reason => {
          $.NotificationApp.send('Error', reason.error.error.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        }
      );
    }
  }
}
