import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../services/user';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
declare let Vimeo: any;

@Component({
  selector: 'app-my-workout',
  templateUrl: './my-workout.component.html',
  styleUrls: ['./my-workout.component.scss']
})
export class MyWorkoutComponent implements OnInit, OnDestroy, AfterViewInit {
  public user: User;
  private semana: string;
  private dia: string;
  private sub: Subscription;
  private player: any;
  private rutina: any;
  private videos: any[];
  private flags: boolean[];

  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth, private route: ActivatedRoute) {
    this.user = this.afAuth.auth.currentUser;
    this.semana = this.route.snapshot.paramMap.get('semana');
    this.dia = this.route.snapshot.paramMap.get('dia');
    this.videos = [];
    this.flags = [];
  }
  ngAfterViewInit(): void {
    /*const div = document.getElementById('videos');
    const video = document.createElement('script');
    video.id = 'e52d8678-e7d6-fdac-65af-6f3d6260f717-vod-756ffc17-cbbb-f3ab-6295-ee2d4e5b79aa';
    video.style.width = '100%';
    video.style.height = '100%';
    video.classList.add('dacast-video');
    video.src = `https://player.dacast.com/js/player.js?contentId=e52d8678-e7d6-fdac-65af-6f3d6260f717-vod-756ffc17-cbbb-f3ab-6295-ee2d4e5b79aa`;
    console.log(video);
    document.body.append(video);*/
    this.getWorkout();
  }

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    if(this.sub) this.sub.unsubscribe();
    //this.player.dispose();
  }

  private getWorkout(): void{
    this.sub = this.db.collection('users').doc(this.user.uid).collection('rutina', ref => ref.where('semana','==',this.semana).where('dia','==',this.dia)).valueChanges({ idField: 'documentId' }).subscribe(
      workout => {
        if(workout.length > 0){
          this.rutina = workout.pop();
          const videos = this.rutina.workouts.map(v => this.db.collection('workouts').doc(v).get().toPromise().then(d => ({ id: d.id, data: d.data() })));
          
          Promise.all(videos).then(values => {
           
            this.videos = values;
            this.flags = Object.keys(values).map(v => false);
            /*this.player = videojs('video', {
              aspectRatio: '16:9'
            });
            */
            
            /*
            this.player.on('timeupdate', async event => {
              const percentage = Math.floor((this.player.currentTime()/this.player.duration()) * 100);
              const currentVideo = this.player.playlist.currentItem();
              if(percentage >= 50 && !this.flags[currentVideo]){
                this.flags[currentVideo] = true;
                const videoId = values[currentVideo]['id'];
                const workouts = await this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).get().toPromise().then(data => data.data());
                if(workouts.vistos){
                  if(workouts.vistos.indexOf(videoId) == -1){
                    let vistos = workouts.vistos;
                    vistos.push(videoId);
                    this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
                      vistos: vistos
                    }, { merge: true });
                  }
                } else{
                  this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
                    vistos: [videoId]
                  }, { merge: true });
                }
              }
              
            });*/
            //Filter para quitar los videos que tengan el parametro de dacast ya que despues se van a agregar de otra manera

            const divVideos = document.getElementById('videos');

            values.filter(v => v['data']['html']).forEach((v: any, i) => {
              const div = document.createElement('div');
              div.classList.add('col-12');
              //div.classList.add('video-player');
              //div.classList.add('mb-4');
              div.innerHTML = `<div class="video-player"><iframe class="vimeo" id="${i}${v['data']['html']}" src="https://player.vimeo.com/video/${v['data']['html']}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>`;
              divVideos.append(div);
              const iframe = document.getElementById(`${i}${v['data']['html']}`);
              const player = new Vimeo.Player(iframe);
              
              player.on('timeupdate', async data => {
                if(data.percent >= .5 && !this.flags[i]){
                  this.flags[i] = true;
                  const workouts = await this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).get().toPromise().then(data => data.data());
                  if(workouts.vistos){
                    if(workouts.vistos.indexOf(v.id) == -1){
                      let vistos = workouts.vistos;
                      vistos.push(v.id);
                      this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
                        vistos: vistos
                      }, { merge: true });
                    }
                  } else{
                    this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
                      vistos: [v.id]
                    }, { merge: true });
                  }
                }
              });
            });

          });
        }
        /**
         * Promise.all(this.videos).then(() => {
            setTimeout(() => {
              const videoTags: any = document.getElementsByClassName('video-js')
              videoTags.forEach(e => videojs(e.id, {
                aspectRatio: '16:9'
              }));
            });
          });
         */
      }
    );
  }

  public async marcarComoVisto(): Promise<any>{
    for(const video of this.videos){
      const workouts = await this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).get().toPromise().then(data => data.data());
      const videoId = video['id'];
      if(workouts.vistos){
        if(workouts.vistos.indexOf(videoId) == -1){
          let vistos = workouts.vistos;
          vistos.push(videoId);
          this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
            vistos: vistos
          }, { merge: true });
        }
      } else{
        this.db.collection('users').doc(this.user.uid).collection('rutina').doc(this.rutina.documentId).set({
          vistos: [videoId]
        }, { merge: true });
      }
    }
  }
}
