import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
declare let $: any;

@Component({
  selector: 'app-agregar-video',
  templateUrl: './agregar-video.component.html',
  styleUrls: ['./agregar-video.component.scss']
})
export class AgregarVideoComponent implements OnInit {
  public form: FormGroup;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required]),
      vimeo: new FormControl('', [Validators.required]),
      seccion: new FormControl('')
    });
  }

  ngOnInit() {
  }

  public async submit(): Promise<void>{
    console.log(this.form.value)
    if(this.form.valid){
      const reponse = await this.db.collection('workouts').add({
        category: this.form.value.category,
        html: this.form.value.vimeo,
        title: this.form.value.title,
        seccion: this.form.value.seccion
      });
      this.form.setValue({
        title: '',
        category: '',
        vimeo: '',
        seccion: ''
      });
      $('.needs-validation').removeClass('was-validated');
      $('#thumbnail').val('');
    }
  }

  public subirThumbnail(e): void{
    const files = e.target.files;
    if(files.length > 0){
      const file = files[0];
      this.storage.upload(`Thumbnails/${Date.now()}`, file).then(async r => {
        const url = await r.ref.getDownloadURL();
        this.form.get('thumbnail').setValue(url);
      });
    }
  }
}
