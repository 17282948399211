import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './../services/auth.service';
import { StripeService } from './../services/stripe.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;


@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
export class Login2Component implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;
  private customer: string;
  public renewUrl: string;
  constructor(private auth: AuthService, private afAuth: AngularFireAuth, private router: Router, private stripeService: StripeService, private route: ActivatedRoute) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
    this.customer = this.route.snapshot.paramMap.get('customer') ? atob(this.route.snapshot.paramMap.get('customer')) : '';
    this.renewUrl = '';
  }

  ngOnInit() {
    this.afAuth.auth.signOut();
    if (this.customer) this.customerPortal();
  }

  ngAfterViewInit(): void {
    document.body.classList.add("authentication-bg");
    $.App.init();
    $('.slider').slick({
      speed: 300,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1
    });
    if (this.customer) {
      //Mostrar modal
      $('#renew').modal('show');
    }
  }

  ngOnDestroy(): void {
    $.App.init();
    document.body.classList.remove("authentication-bg");
  }

  public submit() {
    if (this.form.valid) {
      this.auth.signIn(this.form.value.email, this.form.value.password).then(
        user => {
          this.router.navigate(['/']);
        },
        reason => {
          $.NotificationApp.send(reason.code, reason.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        });
    }
  }

  public customerPortal(): void {
    this.stripeService.customerPortal(this.customer, 'https://andrealaveaga.com/').then(
      session => {
        this.renewUrl = session.url;
      },
      reason => {
        console.log(reason.error.error.message);
        $.NotificationApp.send('Error', reason.error.error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }
    );
  }

}
