import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { TopbarComponent } from './topbar/topbar.component';
import { LeftsideMenuComponent } from './leftside-menu/leftside-menu.component';
import { ConfirmacionMailComponent } from './confirmacion-mail/confirmacion-mail.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { WorkoutsComponent } from './workouts/workouts.component';
import { WorkoutComponent } from './workout/workout.component';
import { VideoComponent } from './video/video.component';
import { AgregarRecetaComponent } from './agregar-receta/agregar-receta.component';


//Material design
import { MatAutocompleteModule } from '@angular/material/autocomplete';

//Firebase modules
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatVideoModule } from 'mat-video';
import { RecetasComponent } from './recetas/recetas.component';
import { RecetasCategoriaComponent } from './recetas-categoria/recetas-categoria.component';
import { RecetaComponent } from './receta/receta.component';
import { ClientesComponent } from './clientes/clientes.component';
import { WorkoutsUsuarioComponent } from './workouts-usuario/workouts-usuario.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { MamacitasComponent } from './mamacitas/mamacitas.component';
import { TipsComponent } from './tips/tips.component';
import { MamacitasWorkoutsComponent } from './mamacitas-workouts/mamacitas-workouts.component';
import { AcercaDeMiComponent } from './acerca-de-mi/acerca-de-mi.component';
import { TerminosComponent } from './terminos/terminos.component';
import { MyWorkoutComponent } from './my-workout/my-workout.component';
import { CouponsComponent } from './coupons/coupons.component';
import { AvanceComponent } from './avance/avance.component';
import { CrearUsuarioVipComponent } from './crear-usuario-vip/crear-usuario-vip.component';
import { CrearTemplateComponent } from './crear-template/crear-template.component';
import { ClientesSinMigrarComponent } from './clientes-sin-migrar/clientes-sin-migrar.component';
import { SugerenciasUsuarioComponent } from './sugerencias-usuario/sugerencias-usuario.component';
import { AgregarVideoComponent } from './agregar-video/agregar-video.component';
import { FaqComponent } from './faq/faq.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { ContactoComponent } from './contacto/contacto.component';
import { EditarClasesComponent } from './editar-clases/editar-clases.component';
import { MatTableModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { EditarClaseComponent } from './editar-clase/editar-clase.component';
import { ListaClientesComponent } from './lista-clientes/lista-clientes.component';
import { Login2Component } from './login2/login2.component';
import { MantenimientoComponent } from './mantto/mantenimiento/mantenimiento.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    HomeComponent,
    Error404Component,
    TopbarComponent,
    LeftsideMenuComponent,
    ConfirmacionMailComponent,
    SignUpComponent,
    WorkoutsComponent,
    WorkoutComponent,
    VideoComponent,
    AgregarRecetaComponent,
    RecetasComponent,
    RecetasCategoriaComponent,
    RecetaComponent,
    ClientesComponent,
    WorkoutsUsuarioComponent,
    MiCuentaComponent,
    MamacitasComponent,
    TipsComponent,
    MamacitasWorkoutsComponent,
    AcercaDeMiComponent,
    TerminosComponent,
    MyWorkoutComponent,
    CouponsComponent,
    AvanceComponent,
    CrearUsuarioVipComponent,
    CrearTemplateComponent,
    ClientesSinMigrarComponent,
    SugerenciasUsuarioComponent,
    AgregarVideoComponent,
    FaqComponent,
    SignInComponent,
    ContactoComponent,
    EditarClasesComponent,
    EditarClaseComponent,
    ListaClientesComponent,
    Login2Component,
    MantenimientoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    MatVideoModule,
    AngularFireStorageModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
