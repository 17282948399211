import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Angular-Log-In-Firebase';
  constructor(public router: Router){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'UA-166528202-1',{'page_path': event.urlAfterRedirects});
        $('body').removeClass('sidebar-enable');
      }
    })
  }
}
