import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
declare let $: any;


@Component({
  selector: 'app-editar-clase',
  templateUrl: './editar-clase.component.html',
  styleUrls: ['./editar-clase.component.scss']
})
export class EditarClaseComponent implements OnInit {
  public form: FormGroup;
  private id: string;
  public clase: any;
  public cargando: boolean;

  constructor(private route: ActivatedRoute, private db: AngularFirestore, private sanitizer: DomSanitizer) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.clase = {};

    this.form = new FormGroup({
      title: new FormControl('', []),
      category: new FormControl('', []),
      seccion: new FormControl('', [Validators.required]),
      pesas: new FormControl(false, []),
      step: new FormControl(false, []),
      cuerda: new FormControl(false, [])
    });

    this.cargando = false;

  }

  ngOnInit() {
    this.getClase();
  }

  private async getClase() {
    this.db.collection('workouts').doc<any>(this.id).valueChanges().subscribe(
      clase => {
        this.clase = clase;

        this.form.setValue({
          title: this.clase.title || '-',
          pesas: this.clase.pesas == null ? false : this.clase.pesas,
          step: this.clase.step == null ? false : this.clase.step,
          cuerda: this.clase.cuerda == null ? false : this.clase.cuerda,
          category: this.clase.category || '-',
          seccion: this.clase.seccion || '-',
        });


      },
      error => {
        console.log(error);
      }
    );





  }

  public async submit(): Promise<void> {
    console.log('Guardar');
    if (this.form.valid) {
      console.log('Valido');
      this.cargando = true;
      const response = await this.db.collection('workouts').doc(this.id).set({
        category: this.form.value.category,
        //html: this.form.value.vimeo,
        title: this.form.value.title,
        seccion: this.form.value.seccion,
        pesas: this.form.value.pesas,
        step: this.form.value.step,
        cuerda: this.form.value.cuerda
        //thumbnail: this.form.value.thumbnail
      }, { merge: true });

      $('.needs-validation').removeClass('was-validated');
      //$('#thumbnail').val('');
      console.log(response);
      this.cargando = false;
    } else {
      console.log("Not valid");
      console.log(this.form.errors);
    }
  }
}
