import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-avance',
  templateUrl: './avance.component.html',
  styleUrls: ['./avance.component.scss']
})
export class AvanceComponent implements OnInit {
  public users: any[];
  public semanas: string[];
  public loading: boolean;

  constructor(private db: AngularFirestore) {
    this.users = [];
    this.semanas = [];
    this.loading = false;
  }

  ngOnInit() {
    this.getRutinas();
    console.log('Total Weeks in Year: ', this.calculateISOWeeks(2023));
  }

  private async getRutinas(): Promise<void> {
    var { semana, year } = await fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json());

    this.semanas.push(`${semana}`.padStart(2, '0'));
    //this.semanas.push(`${semana+1}`.padStart(2,'0'));
    if (semana - 1 > 0) this.semanas.push(`${semana - 1}`.padStart(2, '0'));
    if (semana - 2 > 0) this.semanas.push(`${semana - 2}`.padStart(2, '0'));
    //if(semana < 10){ semana = '0' + semana }
    this.db.collection('users').valueChanges({ idField: 'documentId' }).subscribe(
      (response: any) => {
        this.users = [];
        response.sort((a, b) => {
          if (a.email > b.email) return 1;
          if (a.email < b.email) return -1;

          return 0;
        }).forEach(async u => {
          let query = ref => ref.where('semana', 'in', this.semanas);
          const rutinas = await this.db.collection('users').doc(u.documentId).collection('rutina', query).get().toPromise().then(d => d.docs.map(r => r.data()));
          u.semanas = [];
          rutinas.filter(r => {
            const yearRutina = r.year || '2020';
            return yearRutina == `${year}`
          }).forEach(r => {
            const indexSemana = u.semanas.findIndex(s => s.numero == r.semana);
            if (indexSemana == -1) {
              u.semanas.push({
                numero: r.semana,
                dias: [r],
                workouts: r.workouts.length,
                vistos: r.vistos ? r.vistos.length : 0
              });
            } else {
              u.semanas[indexSemana].dias.push(r);
              u.semanas[indexSemana].workouts += r.workouts.length;
              u.semanas[indexSemana].vistos += r.vistos ? r.vistos.length : 0;
              u.semanas[indexSemana].dias.sort((a, b) => {
                if (a.dia > b.dia) return 1;
                if (a.dia < b.dia) return -1;

                return 0;
              });
            }
          });
          u.semanas.map(s => {
            s.porcentaje = parseFloat((s.vistos / s.workouts * 100).toFixed(2));

            return s;
          });
          u.semanas = this.semanas.map(s => {
            let semana = u.semanas.find(w => w.numero == s);
            if (!semana)
              semana = {
                numero: `${s}`,
                dias: [],
                workouts: 0,
                vistos: 0,
                porcentaje: 0
              };
            return semana;
          });
          this.users.push(u);
        });

      },
      error => {
        console.log(error);
      }
    );
  }

  public calculateISOWeeks(year) {
    const firstDay = new Date(year, 0, 1);
    console.log('First Day: ', firstDay);
    const dayNumber = firstDay.getDay();
    console.log('Day Number: ', dayNumber); 

    if(dayNumber > 4){
      return 53;
    } else {
      return 52;
    }

  }

  public async validarAvance(): Promise<void> {
    this.loading = true;

    var { semana, year } = await fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json());
    //if(semana < 10){ semana = '0' + semana }
    const users = this.users.filter(u => u.semanas[0].porcentaje < 50).map(u => u.documentId);

    /*if(u.semanas[0].porcentaje >= 50){
      this.loading = false;
      return;
    }*/

    //const user = u.documentId;    
    for (const user of users) {
      let rutinas = await this.db.collection('users').doc(user).collection('rutina', ref => ref.where('semana', '==', `${semana}`.padStart(2, '0'))).get().toPromise().then(d => d.docs.map(r => ({ id: r.id, data: r.data() })));
      rutinas = rutinas.filter(r => {
        const yearRutina = r.data.year || '2020';
        return yearRutina == `${year}`;
      });
      let weeks = this.calculateISOWeeks(year);
      let rutinasProximaSemana = await this.db.collection('users').doc(user).collection('rutina', ref => ref.where('semana', '==', `${(((semana + 1) - 1) % weeks) + 1}`.padStart(2, '0'))).get().toPromise().then(d => d.docs.map(r => ({ id: r.id, data: r.data() })));
      let rutinasProximaSemana2 = await this.db.collection('users').doc(user).collection('rutina', ref => ref.where('semana', '==', `${(((semana + 2) - 1) % weeks) + 1}`.padStart(2, '0'))).get().toPromise().then(d => d.docs.map(r => ({ id: r.id, data: r.data() })));
      let rutinasProximaSemana3 = await this.db.collection('users').doc(user).collection('rutina', ref => ref.where('semana', '==', `${(((semana + 3) - 1) % weeks) + 1}`.padStart(2, '0'))).get().toPromise().then(d => d.docs.map(r => ({ id: r.id, data: r.data() })));
      let rutinasProximaSemana4 = await this.db.collection('users').doc(user).collection('rutina', ref => ref.where('semana', '==', `${(((semana + 4) - 1) % weeks) + 1}`.padStart(2, '0'))).get().toPromise().then(d => d.docs.map(r => ({ id: r.id, data: r.data() })));
      rutinasProximaSemana = rutinasProximaSemana.filter(r => {
        const yearRutina = r.data.year || '2020';
        return yearRutina == `${(semana + 1) > weeks ? year + 1 : year}`;
      });
      rutinasProximaSemana2 = rutinasProximaSemana2.filter(r => {
        const yearRutina = r.data.year || '2020';
        return yearRutina == `${(semana + 2) > weeks ? year + 1 : year}`;
      });
      rutinasProximaSemana3 = rutinasProximaSemana3.filter(r => {
        const yearRutina = r.data.year || '2020';
        return yearRutina == `${(semana + 3) > weeks ? year + 1 : year}`;
      });
      rutinasProximaSemana4 = rutinasProximaSemana4.filter(r => {
        const yearRutina = r.data.year || '2020';
        return yearRutina == `${(semana + 4) > weeks ? year + 1 : year}`;
      });

      //Recorrer las rutinas de la proximana semana y la actual
      rutinasProximaSemana4.forEach(rutina => {
        this.db.collection('users').doc(user).collection('rutina').doc(rutina.id).set({
          semana: `${(((parseInt(semana) + 5) - 1) % weeks) + 1}`.padStart(2, '0'),
          year: `${(parseInt(semana) + 5) > weeks ? year + 1 : year}`
        }, { merge: true });
        this.db.collection('users').doc(user).set({
          semana: `${(((parseInt(semana) + 5) - 1) % weeks) + 1}`.padStart(2, '0'),
        }, { merge: true });
      });
      rutinasProximaSemana3.forEach(rutina => {
        this.db.collection('users').doc(user).collection('rutina').doc(rutina.id).set({
          semana: `${(((parseInt(semana) + 4) - 1) % weeks) + 1}`.padStart(2, '0'),
          year: `${(parseInt(semana) + 4) > weeks ? year + 1 : year}`
        }, { merge: true });
        this.db.collection('users').doc(user).set({
          semana: `${(((parseInt(semana) + 4) - 1) % weeks) + 1}`.padStart(2, '0'),
        }, { merge: true });
      });
      rutinasProximaSemana2.forEach(rutina => {
        this.db.collection('users').doc(user).collection('rutina').doc(rutina.id).set({
          semana: `${(((parseInt(semana) + 3) - 1) % weeks) + 1}`.padStart(2, '0'),
          year: `${(parseInt(semana) + 3) > weeks ? year + 1 : year}`
        }, { merge: true });
        this.db.collection('users').doc(user).set({
          semana: `${(((parseInt(semana) + 3) - 1) % weeks) + 1}`.padStart(2, '0'),
        }, { merge: true });
      });
      rutinasProximaSemana.forEach(rutina => {
        this.db.collection('users').doc(user).collection('rutina').doc(rutina.id).set({
          semana: `${`${(((parseInt(semana) + 2) - 1) % weeks) + 1}`}`.padStart(2, '0'),
          year: `${(parseInt(semana) + 2) > weeks ? year + 1 : year}`
        }, { merge: true });
        this.db.collection('users').doc(user).set({
          semana: `${(((parseInt(semana) + 2) - 1) % weeks) + 1}`.padStart(2, '0'),
        }, { merge: true });
      });
      rutinas.forEach(rutina => {
        rutina.data.semana = `${`${(((parseInt(semana) + 1) - 1) % weeks) + 1}`}`.padStart(2, '0');
        rutina.data.year = `${(parseInt(semana) + 1) > weeks ? year + 1 : year}`;
        rutina.data.repetida = true;
        this.db.collection('users').doc(user).collection('rutina').add(rutina.data);
        this.db.collection('users').doc(user).set({
          semana: `${(((parseInt(semana) + 1) - 1) % weeks) + 1}`.padStart(2, '0'),
        }, { merge: true });
      });
    }
    this.semanas = [];
    this.getRutinas();
    this.loading = false;
  }

  public toNumber(n): number {
    return !n || isNaN(parseFloat(n)) ? 0 : parseFloat(n);
  }

  
}
