import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { StripeService } from './../services/stripe.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private stripeService: StripeService, private db: AngularFirestore) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.log('Guard');
    return this.auth.$user.pipe(
      map(user => {
        if(user){
          this.db.collection('users').doc(user.uid).get().subscribe(u => {
            //Validar si hay un nodo en firestore para este usuario
            if(u.exists){
              const data = u.data();
              //Hacer consulta a stripe para ver la info de la suscripcion
              this.stripeService.getSubscription(data.subscription).then(
                subscription => {
                  const status = subscription.status;
                  if(status != 'trialing' && status != 'active'){
                    console.log(status);
                   /* this.router.navigate(['/login']);
                    $.NotificationApp.send('Error', 'Your subscription is not active.', 'bottom-right', '#fa5c7c', 'error', 10000);
                    return;
                  }else{*/
                    this.router.navigate(['/mi-cuenta']);
                    $.NotificationApp.send('Error', 'Your subscription is not active.', 'bottom-right', '#fa5c7c', 'error', 10000);
                    return;
                  }
                },
                reason =>{
                  console.log(reason.error.error.message);
                  this.router.navigate(['/registro']);
                  $.NotificationApp.send('Error', reason.error.error.message, 'bottom-right', '#fa5c7c', 'error', 10000);
                  return;
                }
              );
            } else{
              console.log(`Subscription for user ${user.uid} doesn't exist.`);
              this.router.navigate(['/registro']);
              $.NotificationApp.send('Error', `Subscription for user ${user.uid} doesn't exist.`, 'bottom-right', '#fa5c7c', 'error', 10000);
              return;
            }
          });
        }

        return !!user;
      })
    );
  }
  
}
