import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StripeService } from './../services/stripe.service';
import { AuthService } from './../services/auth.service';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
declare var $: any;
declare var Stripe: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  public formCuenta: FormGroup;
  public formTarjeta: FormGroup;
  public formCupon: FormGroup;
  private stripe: any;
  private elements: any;
  private card: any;
  public error: string;
  public tarjetaValida: boolean;
  public procesando: boolean;
  public pagado: boolean;
  public validandoCupon: boolean;
  public errorCupon: string;
  public mensajeCuponValido: string;
  public pagoPaypal: boolean;

  constructor(private auth: AuthService, private stripeService: StripeService, private db: AngularFirestore, private afAuth: AngularFireAuth) {
    this.formCuenta = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      fullname: new FormControl('', [Validators.required, Validators.minLength(6)]),
			password: new FormControl('', [Validators.required])
    });
    this.stripe = Stripe(environment.stripe.publishableKeyAF);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });
    this.error = '';
    this.tarjetaValida = false;
    this.procesando = false;
    this.pagado = false;
    this.formCupon = new FormGroup({
      cupon: new FormControl('', [Validators.required])
    });
    this.validandoCupon = false;
    this.errorCupon = '';
    this.mensajeCuponValido = '';
    this.pagoPaypal = false;
  }

  ngOnInit() {
    this.afAuth.auth.signOut();
  }

  ngAfterViewInit(): void {
    document.body.classList.add("authentication-bg");
    $.App.init();
    $("#progressbarwizard").bootstrapWizard({onTabShow:function(t,r,a){var o=(a+1)/r.find("li").length*100;
    $("#progressbarwizard").find(".bar").css({width:o+"%"})}});
    this.card.mount('#card-element');
    this.card.mount('#card-element-mobile');
    this.card.on('change', (e) => this.cardHandler(e));
  }

  ngOnDestroy(): void {
    $.App.init();
    document.body.classList.remove("authentication-bg");
    this.card.removeEventListener('change', (e) => this.cardHandler(e));
    this.card.destroy();
  }
  
  public submitCuenta(): void{
    if(this.formCuenta.valid){
      document.getElementById('step2').click();
    }
  }

  private cardHandler(event): void{
    if(event.complete){
      this.pagoPaypal = false;
      this.error = '';
      this.tarjetaValida = true;
    } else if(event.error){
      this.pagoPaypal = true;
      this.error = event.error.message;
      this.tarjetaValida = false;
    } else{
      this.pagoPaypal = false;
      this.error = '';
      this.tarjetaValida = false;
    }
  }

  public async submitPago(): Promise<void>{
    this.procesando = true;
    //Registro de usuario
    const user = await this.auth.signUp(this.formCuenta.value.email, this.formCuenta.value.password).then(
      user => {
        return user;
      },
      reason => {
        this.error = reason.message;
        this.procesando = false;
        return;
      }
    );

    if(!user) return;

    await user.updateProfile({ displayName: this.formCuenta.value.fullname }).catch(reason => {
      this.error = reason.message;
      this.procesando = false;
      return;
    });

    //Proceso de pago
    const config = {
      currency: 'MXN',
      email: this.formCuenta.value.email
    };
    const { token, error } = await this.stripe.createToken(this.card, config);

    if(error){
      this.error = error.message;
      this.procesando = false;
      await user.delete();
      return;
    }

    this.stripeService.createCustomer(this.formCuenta.value.email, this.formCuenta.value.fullname, token.id).then(
      customer => {
        //Validar si tiene un cupon
        let cupon = '';
        if(this.formCupon.disabled && !this.validandoCupon && this.formCupon.value.cupon.trim()){
          cupon = this.formCupon.value.cupon.trim();
        }
        
        this.stripeService.createSubscription(customer.id, environment.stripe.membership, cupon).then(
          subscription => {
            // Guardar id de suscripcion y del cliente 
            this.db.collection('users').doc(user.uid).set({
              customer: customer.id,
              stripeAF: true,
              subscription: subscription.id,
              email: user.email
            }, { merge: true }).catch(reason => {
              this.error = reason.message;
              console.log(this.error);
              this.procesando = false;
              return;
            });

            
            /** Enviar peticion a funcion remota para que envie correo de cliente nuevo */
            const xhr = new XMLHttpRequest();
            const data = {
              name: this.formCuenta.value.fullname,
              email: this.formCuenta.value.email,
              customer: customer.id 
            };
            xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/userCreated');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
            /** Fin correo de cliente nuevo */

            this.error = '';
            this.procesando = false;
            this.pagado = true;
            setTimeout(() => { document.getElementById('step3').click(); }, 0)
            return;
          },
          reason => {
            this.pagoPaypal = true;
            this.error = reason.error.error.message;
            /** Enviar peticion a funcion remota para que envie correo de error */
            const xhr = new XMLHttpRequest();
            const data = {
              name: this.formCuenta.value.fullname,
              email: this.formCuenta.value.email,
              error: this.error
            };
            xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
            /** Fin correo de error */
            this.procesando = false;
            user.delete();
            return;
          }
        );
      },
      reason => {
        this.error = reason.error.error.message;
        /** Enviar peticion a funcion remota para que envie correo de error */
        const xhr = new XMLHttpRequest();
        const data = {
          name: this.formCuenta.value.fullname,
          email: this.formCuenta.value.email,
          error: this.error
        };
        xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
        xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
        xhr.send(JSON.stringify(data));
        /** Fin correo de error */
        this.procesando = false;
        user.delete();
        return;
      }
    );
  }

  public validarCupon(): void{
    if(this.formCupon.valid){
      this.validandoCupon = true;
      this.formCupon.disable();
      this.stripeService.getCoupons().then(
        response => {
          const cupones: any[] = response.data;
          const cupon = cupones.find(c => c.code == this.formCupon.value.cupon.trim());

          if(!cupon || !cupon.active || !cupon.coupon.valid){
            this.errorCupon = 'Cupón inválido.';
            this.validandoCupon = false;
            this.formCupon.enable();
            this.formCupon.reset();
            return;
          }

          this.validandoCupon = false;
          this.errorCupon = '';
          this.mensajeCuponValido = `Descuento de USD $${parseFloat(`${cupon.coupon.amount_off/100}`)}`;
          return;

          /*if(cupon.valid){
            
          } else{
            
          }*/
        },
        reason => {
          this.errorCupon = reason.error.error.message;
          this.validandoCupon = false;
          
          this.formCupon.enable();
          this.formCupon.reset();
          return;
        }
      );
    } else{
      this.errorCupon = '';
    }
  }

  public cancelarCupon(): void{
    this.formCupon.enable();
    this.formCupon.reset();
    this.mensajeCuponValido = '';
    return;
  }

  public async pagarConPaypal(): Promise<void>{
    this.procesando = true;

    //Registro de usuario
    const user = await this.auth.signUp(this.formCuenta.value.email, this.formCuenta.value.password).then(
      user => {
        return user;
      },
      reason => {
        this.error = reason.message;
        this.procesando = false;
        return;
      }
    );

    if(!user) return;

    await user.updateProfile({ displayName: this.formCuenta.value.fullname }).catch(reason => {
      this.error = reason.message;
      this.procesando = false;
      return;
    });

    this.stripeService.createCustomer(this.formCuenta.value.email, this.formCuenta.value.fullname ).then(
      customer => {
        const trialEnd = (new Date().getTime() + 3000).toString().slice(0,-3);
        //Validar si tiene un cupon
        let cupon = '';
        if(this.formCupon.disabled && !this.validandoCupon && this.formCupon.value.cupon.trim()){
          cupon = this.formCupon.value.cupon.trim();
        }
        
       /* this.stripeService.createSubscription(customer.id, environment.stripe.membership, cupon, trialEnd, 'create_prorations').subscribe(
          subscription => {*/
            // Guardar id de suscripcion y del cliente 
            this.db.collection('users').doc(user.uid).set({
              customer: customer.id,
              stripeAF: true,
              subscription: '',//subscription.id,
              email: user.email
            }, { merge: true }).catch(reason => {
              this.error = reason.message;
              console.log(this.error);
              this.procesando = false;
              return;
            }).finally(() => {
              console.log('ok');
              location.href = cupon ? 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-81407571WJ9776801L5NCKDQ' : 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-9VB46043264114839L5NCI2I';
            });/*
          },
          reason => {
            this.error = reason.error.error.message;
            //Enviar peticion a funcion remota para que envie correo de error
            const xhr = new XMLHttpRequest();
            const data = {
              name: this.formCuenta.value.fullname,
              email: this.formCuenta.value.email,
              error: this.error
            };
            xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
            //Fin correo de error
            this.procesando = false;
            user.delete();
            return;
          }
        );*/
      },
      reason => {
        this.error = reason.error.error.message;
        /** Enviar peticion a funcion remota para que envie correo de error */
        const xhr = new XMLHttpRequest();
        const data = {
          name: this.formCuenta.value.fullname,
          email: this.formCuenta.value.email,
          error: this.error
        };
        xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
        xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
        xhr.send(JSON.stringify(data));
        /** Fin correo de error */
        this.procesando = false;
        user.delete();
        return;
      }
    );
  }
}
