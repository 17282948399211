import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { toUnicode } from 'punycode';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { async } from '@angular/core/testing';
declare var $: any;


@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styleUrls: ['./mantenimiento.component.scss']
})
export class MantenimientoComponent implements OnInit {
  public porDepurar : any;
  public users: any;
  public rutinas: any;
  public havingInfo: boolean = false;
  constructor(private db: AngularFirestore) { 
    this.porDepurar = [];
  
  }

  ngOnInit() {
  }

  async getCustomers(){
    console.log('starting...')
    this.users = await this.db.collection('users').get().toPromise().then(
      user => {
        return user.docs.map(
          data => data.id
        )
      },
      error => {
        console.log(error);
      }
    ).catch( (error:any) => {
      console.log(error)
    });
    this.getRutinas()
  }

  async getRutinas(){
    let i = 0;
    var refreshIntervalId = setInterval(
      async () => {
        this.rutinas = await this.db.collection('users').doc(this.users[i]).collection('rutina').get().toPromise().then(
          data => data.docs.map( (e:any) => {
            return {
              'id': e.id,
              'data': e.data()
            }
          }),
          error => {
            console.log(error);
          }
        ).catch( (error:any) => {
          console.log(error)
        });
        this.rutinas.forEach( async (element:any) => {
          if(element.data.year == undefined || element.data.year == 2020){

            console.log(element)
            this.porDepurar.push(element)
          }
        });
        i++;
        console.log(i)
        if(i == this.users.length){
          clearInterval(refreshIntervalId);
          //this.havingInfo = true;
          console.log(this.porDepurar)
          console.log('already end')
        }
      }
    ,500)
  }
}
