import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-sugerencias-usuario',
  templateUrl: './sugerencias-usuario.component.html',
  styleUrls: ['./sugerencias-usuario.component.scss']
})
export class SugerenciasUsuarioComponent implements OnInit {
  private uid: string;
  public user: firebase.User;
  public form: FormGroup;
  public recetas: any[];
  public queryRecetas1: string;
  public queryRecetas2: string;
  public tips: any[];
  public queryTips: string;

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) {
    this.user = this.afAuth.auth.currentUser;
    this.form = new FormGroup({
      semana: new FormControl('', [Validators.required]),
      receta1: new FormControl('', [Validators.required]),
      receta2: new FormControl('', [Validators.required]),
      tips: new FormControl('')
    });
    this.recetas = [];
    this.queryRecetas1 = '';
    this.queryRecetas2 = '';
    this.tips = [];
    this.queryTips = '';
  }

  ngOnInit() {
    this.getRecetas();
    this.getTips();
  }

  private async getRecetas(): Promise<void> {
    this.recetas = await this.db.collection('recetas').get().toPromise().then(r => r.docs.map(d => {
      let data = d.data();
      data.id = d.id;
      return data;
    }));
  }

  private async getTips(): Promise<void> {
    this.tips = await this.db.collection('tips').get().toPromise().then(r => r.docs.map(d => {
      let data = d.data();
      data.id = d.id;
      return data;
    }));
  }

  public submit(): void {
    const indexReceta1 = this.recetas.findIndex(r => r.id == this.form.get('receta1').value);
    if (indexReceta1 == -1) {
      this.form.get('receta1').setValue('');
      this.queryRecetas1 = '';
    }
    const indexReceta2 = this.recetas.findIndex(r => r.id == this.form.get('receta2').value);
    if (indexReceta2 == -1) {
      this.form.get('receta2').setValue('');
      this.queryRecetas2 = '';
    }
    const indexTips = this.tips.findIndex(t => t.id == this.form.get('tips').value);
    if (indexTips == -1) {
      this.form.get('tips').setValue('');
      this.queryTips = '';
    }

    if (this.form.valid) {
      const week: string[] = this.form.value.semana.split('-');
      const semana = week.pop().replace(/[Ww]/g, '');
      const year = week.pop();
      this.db.collection('recomendaciones').doc(`s${semana}`).set({
        r1: {
          categoria: 'recetas',
          id: this.form.value.receta1
        },
        r2: {
          categoria: 'recetas',
          id: this.form.value.receta2
        },
        r3: {
          categoria: 'tips',
          id: this.form.value.tips
        },
        semana: semana,
        year: year
      }, { merge: true });
      this.form.setValue({
        semana: '',
        receta1: '',
        receta2: '',
        tips: '',
      });
      this.queryRecetas1 = '';
      this.queryRecetas2 = '';
      this.queryTips = '';
      setTimeout(() => {
        $('.needs-validation').removeClass('was-validated');
      });
    }
  }

  public displayReceta(id): string {
    const receta = this.recetas.find(c => c.id == id);

    return receta ? receta.title : id;
  }

  public displayTip(id): string {
    const tip = this.tips.find(c => c.id == id);

    return tip ? tip.title : id;
  }

  public consultaRecetas1(e): void {
    this.queryRecetas1 = e.target.value;
  }

  public filtroRecetas1(): any[] {
    return this.queryRecetas1 ? this.recetas.filter(r => r.title.toLowerCase().indexOf(this.queryRecetas1.toLowerCase()) > -1) : this.recetas;
  }

  public consultaRecetas2(e): void {
    this.queryRecetas2 = e.target.value;
  }

  public filtroRecetas2(): any[] {
    return this.queryRecetas2 ? this.recetas.filter(r => r.title.toLowerCase().indexOf(this.queryRecetas2.toLowerCase()) > -1) : this.recetas;
  }

  public consultaTips(e): void {
    this.queryTips = e.target.value;
  }

  public filtroTips(): any[] {
    return this.queryTips ? this.tips.filter(t => t.title.toLowerCase().indexOf(this.queryTips.toLowerCase()) > -1) : this.tips;
  }
}
