import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit, AfterViewInit, OnDestroy {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
		document.body.classList.add("authentication-bg");
	}

	ngOnDestroy(): void {
		document.body.classList.remove("authentication-bg");
	}

}
