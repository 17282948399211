import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../services/user';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { VimeoService } from './../services/vimeo.service';
import { toUnicode } from 'punycode';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { async } from '@angular/core/testing';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public user: User;
  public isAdmin: boolean;
  public recetas: any[];
  public rutinas: any[];
  private sub: Subscription;
  private sub2: Subscription;
  public dias: number;
  public users: any;
  public porcentaje:number;
  public clases: any[];
  public recomendaciones: any[];
  public comentarios: string;
  safeSrc: SafeResourceUrl;

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private sanitizer: DomSanitizer,
    private vimeo: VimeoService) {
    this.user = this.afAuth.auth.currentUser;
    this.isAdmin = environment.admins.indexOf(this.user.email) > -1;
    this.recomendaciones = [];
    this.comentarios = '';
    this.porcentaje = 0;
  }


  ngOnInit() {
    this.getRutinas();
    this.getRecomendaciones();
    //this.dias = this.rutinas.length;


  }
  ngAfterViewInit(): void {
    //$.App.init();
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  private async getRutinas(): Promise<void> {
    const { semana, year } = await fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json());

    this.sub = this.db.collection('users').doc(this.user.uid).collection('rutina', ref => ref.where('semana', '==', `${semana}`).orderBy('dia')).valueChanges({ idField: 'documentId' }).subscribe(
      rutinas => {
        let totalVistos = 0;
        let totalWorkouts = 0;
        this.rutinas = rutinas.filter(rutina => {
          totalWorkouts += rutina.workouts.length
          const yearRutina = rutina.year;
          return yearRutina == `${year}`;
        }).map(rutina => {
          if (rutina.dia == '1') this.comentarios = rutina.comentarios || '';
          rutina.workouts = rutina.workouts.map(async w => {
            let workout = await this.db.collection('workouts').doc(w).get().toPromise().then(doc => { let data = doc.data(); data.documentId = doc.id; return data; });
            this.vimeo.getThumbnail(workout.html).subscribe(
              response => {
                workout.thumbnail = response.data[0].sizes[4].link_with_play_button
              },
              error => {
                console.log(error)
              }
            );
            return workout
          })
          return rutina;
        });
        let vistos = this.rutinas.filter( r => r.vistos != undefined )
        vistos.forEach( v => {
          totalVistos += v.vistos.length;
        });
        this.porcentaje = ( totalVistos / totalWorkouts) * 100
        this.dias = this.rutinas.length;
      },
      error => {
        console.log(error);
      }
    );
    
  }

  public async getRecomendaciones(): Promise<void> {
    const { semana, year } = await fetch('https://us-central1-al-fitness.cloudfunctions.net/getDate').then(response => response.json());
    this.sub2 = this.db.collection('recomendaciones', ref => ref.where('semana', '==', `${semana}`).where('year', '==', `${year}`)).valueChanges({ idField: 'documentId' }).subscribe(
      response => {
        this.recomendaciones = [];
        const recomendaciones: any = response.pop();
        if (recomendaciones) {
          if (recomendaciones.r1) {
            this.recomendaciones.push(recomendaciones.r1.categoria == 'recetas' ? this.db.collection('recetas').doc(recomendaciones.r1.id).get().toPromise().then(doc => { let data = doc.data() || {}; data.documentId = doc.id; data.tipo = 'receta'; return data; }) : this.db.collection('tips').doc(recomendaciones.r1.id).get().toPromise().then(doc => { let data = doc.data() || {}; data.documentId = doc.id; data.tipo = 'tip'; return data; }));
          }
          if (recomendaciones.r2) {
            this.recomendaciones.push(recomendaciones.r2.categoria == 'recetas' ? this.db.collection('recetas').doc(recomendaciones.r2.id).get().toPromise().then(doc => { let data = doc.data() || {}; data.documentId = doc.id; data.tipo = 'receta'; return data; }) : this.db.collection('tips').doc(recomendaciones.r2.id).get().toPromise().then(doc => { let data = doc.data() || {}; data.documentId = doc.id; data.tipo = 'tip'; return data; }));
          }
          if (recomendaciones.r3 && recomendaciones.r3.id != '') {
            this.recomendaciones.push(recomendaciones.r3.categoria == 'recetas' ?
              this.db.collection('recetas').doc(recomendaciones.r3.id).get().toPromise().then(doc => {
                let data = doc.data() || {};
                data.documentId = doc.id;
                data.tipo = 'receta';
                return data;
              }) :
              this.db.collection('tips').doc(recomendaciones.r3.id).get().toPromise().then(doc => {
                let data = doc.data() || {};
                data.documentId = doc.id;
                data.tipo = 'tip';
                data.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/" + data.html)
                return data;
              }));
          }
        }
      },
      error => {
        console.log(error);
      }
    );
  }

}
