import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { StripeService } from '../services/stripe.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var Stripe: any;

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.scss']
})
export class MiCuentaComponent implements OnInit, AfterViewInit, OnDestroy {
  private user: firebase.User;
  public subscription: any;
  public cargando: boolean;
  public error: string;
  public nombre: string;
  public imagen: string;
  public customer: any;

  private s: any;
  private elements: any;
  private card: any;
  public errorTarjeta: string;
  public tarjetaValida: boolean;
  public procesando: boolean;
  

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private stripe: StripeService, private storage: AngularFireStorage) {
    this.user = this.afAuth.auth.currentUser;
    this.error = '';
    this.cargando = false;
    this.nombre = this.user.displayName;
    this.imagen = this.user.photoURL;
    this.errorTarjeta = '';
    this.tarjetaValida = false;
    this.procesando = false;
  }
  ngOnDestroy(): void {
    this.card.removeEventListener('change', (e) => this.cardHandler(e));
    this.card.destroy();
  }
  ngAfterViewInit(): void {
    /*this.card.mount('#card-element');
    this.card.on('change', (e) => this.cardHandler(e));*/
  }

  ngOnInit() {
    this.getCustomerData();
    this.stripeInit();
  }

  private async stripeInit(): Promise<void>{
    const u = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(r => r.data());
    this.s = Stripe(/*u.stripeAF ? */environment.stripe.publishableKeyAF /*: environment.stripe.publishableKey*/);
    this.elements = this.s.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true
    });
    setTimeout(() => {
      this.card.mount('#card-element');
      this.card.on('change', (e) => this.cardHandler(e));
    });
  }

  private cardHandler(event): void{
    if(event.complete){
      this.errorTarjeta = '';
      this.tarjetaValida = true;
    } else if(event.error){
      this.errorTarjeta = event.error.message;
      this.tarjetaValida = false;
    } else{
      this.errorTarjeta = '';
      this.tarjetaValida = false;
    }
  }

  public async getCustomerData(): Promise<void>{
    const subscriptionId = await this.db.collection('users').doc(this.user.uid).get().toPromise().then(doc => doc.data()['subscription']);
    this.subscription = await this.stripe.getSubscription(subscriptionId);
    const customerId = this.subscription.customer;
    this.customer = await this.stripe.getCustomer(customerId);
  }

  public cancelarSuscripcion(): void{
    this.cargando = true;
    this.stripe.cancelAutomaticRenewal(this.subscription.id).then(
      subscription => {
        console.log(subscription);
        this.cargando = false;
        this.error = '';
        this.subscription = subscription;
      },
      reason => {
        this.error = reason.error.error.message;
        /** Enviar peticion a funcion remota para que envie correo de error */
        const xhr = new XMLHttpRequest();
        const data = {
          name: this.user.displayName,
          email: this.user.email,
          error: this.error
        };
        xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
        xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
        xhr.send(JSON.stringify(data));
        /** Fin correo de error */
        this.cargando = false;
        return;
      }
    );
  }

  public renovarSuscripcion(): void{
    this.cargando = true;
    this.stripe.activateAutomaticRenewal(this.subscription.id).then(
      subscription => {
        console.log(subscription);
        this.cargando = false;
        this.error = '';
        this.subscription = subscription;
      },
      reason => {
        this.error = reason.error.error.message;
        /** Enviar peticion a funcion remota para que envie correo de error */
        const xhr = new XMLHttpRequest();
        const data = {
          name: this.user.displayName,
          email: this.user.email,
          error: this.error
        };
        xhr.open('POST', 'https://us-central1-al-fitness.cloudfunctions.net/errorEnPago');
        xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
        xhr.send(JSON.stringify(data));
        /** Fin correo de error */
        this.cargando = false;
        return;
      }
    );
  }

  public async cambiarFotoPerfil(e): Promise<void>{
    if(e.target.files.length > 0){
      const file = e.target.files[0];
      const task = await this.storage.ref('Profile').child(this.user.uid).put(file);
      if(task.state != 'success'){
        $.NotificationApp.send("Error", 'Ocurrio un error al subir la imagen, por favor intenta de nuevo.', 'bottom-right', '#fa5c7c', 'error', 10000);
        return;
      }
      const url: any = await task.task.snapshot.ref.getDownloadURL();
      this.user.updateProfile({ photoURL: url }).then(() => this.imagen = url).catch(reason => {
        $.NotificationApp.send(reason.code, reason.message, 'bottom-right', '#fa5c7c', 'error', 5000);
        return;
      });;
    }
  }

  public cambiarNombre(): void{
    this.user.updateProfile({ displayName: this.nombre }).catch(reason => {
      $.NotificationApp.send(reason.code, reason.message, 'bottom-right', '#fa5c7c', 'error', 5000);
      return;
    });
  }
  
  public modalAgregarTarjeta(): void{
    $('#modalTarjeta').modal('show');
  }

  public async guardarTarjeta(): Promise<void>{
    this.procesando = true;
    const config = {
      currency: 'MXN'
    };
    const { token, error } = await this.s.createToken(this.card, config);

    if(error){
      this.error = error.message;
      this.procesando = false;
      return;
    }

    this.customer = await this.stripe.addCardToCustomer(this.subscription.customer, token.id);
    this.errorTarjeta = '';
    this.procesando = false;
    $('#modalTarjeta').modal('hide');
  }
}
